section.gameKingdomSec {
    background-color: #1B1B1B;
    padding-top: 120px;
    padding-bottom: 120px;
}
.gameKingdomSec .secTitle h1 {
    margin-bottom: 0px;
}
.kingdomText {
    max-width: 1367px;
    margin-left: auto;
    margin-bottom: 100px;
}
.gameKingdomSec .secTitle.kingdomText h1 {
    margin-bottom: 10px;
}
.kingdomComingSoon {
    position: relative;
}
.kingdomComingSoon img {
    width: 100%;
    border-radius: 60px;
    cursor: pointer;
}
.kingdomComingSoon h1 {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150px;
    line-height: 105px;
    margin: 0px;
}
.kingdomComingSoon > .video-react {
    padding-top: 700px !important;
    background-color: #1b1b1b;
}
.kingdomComingSoon .video-react .video-react-big-play-button {
    top: 47%;
    left: 47%;
}
.kingdomComingSoon .video-react .video-react-poster {
    background-color: #1b1b1b;
}



@media (min-width: 320px) and (max-width: 767px) {
    .kingdomComingSoon h1 {
        font-size: 50px;
        line-height: 35px;
    }
    section.gameKingdomSec {
        padding-top: 77px;
        padding-bottom: 69px;
    }
    .kingdomText {
        margin-bottom: 50px;
    }
    .kingdomComingSoon img {
        border-radius: 15px;
    }
    .kingdomComingSoon > .video-react {
        padding-top: 250px !important;
    }
    .kingdomComingSoon .video-react .video-react-big-play-button {
        top: 40%;
        left: 37%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .kingdomComingSoon h1 {
        font-size: 100px;
        line-height: 70px;
    }
    section.gameKingdomSec {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .kingdomComingSoon img {
        border-radius: 30px;
    }
    .kingdomComingSoon > .video-react {
        padding-top: 300px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    section.gameKingdomSec {
        padding-top: 120px;
        padding-bottom: 84px;
    }
    .kingdomComingSoon h1 {
        font-size: 100px;
        line-height: 70px;
    }
    .kingdomComingSoon > .video-react {
        padding-top: 400px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .kingdomComingSoon h1 {
        font-size: 150px;
        line-height: 105px;
    }
    .kingdomText {
        max-width: 890px;
    }
    .kingdomComingSoon > .video-react {
        padding-top: 500px !important;
    }
}
@media (min-width: 1400px) and (max-width: 1699px) {
    .kingdomComingSoon > .video-react {
        padding-top: 600px !important;
    }
}