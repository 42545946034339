@font-face {
  font-family: Roboto-Regular;
  src: url("./fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: Roboto-Medium;
  src: url("./fonts/Roboto-Medium.ttf");
}
@font-face {
  font-family: Roboto-Light;
  src: url("./fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: Roboto-Bold;
  src: url("./fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: Thunder-BoldLC;
  src: url("./fonts/Thunder-BoldLC.ttf");
}
@font-face {
  font-family: Thunder-SemiBoldLC;
  src: url("./fonts/Thunder-SemiBoldLC.ttf");
}

body {
  font-family: Roboto-Regular;
  color: #1B1B1B;
  background-color: #000 !important;
}
h1, h2, h3 {
  font-family: Thunder-BoldLC;
  text-transform: uppercase;
}

@media (min-width: 1700px) {
  .container {
    max-width: 1600px;
    margin: 0px auto;
  }
}
body::-webkit-scrollbar {
  width: 6px;
  background: #000;
}
body::-webkit-scrollbar-thumb {
  background-color: #ffdc64;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header.siteHeader {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 9;
  background-color: #1B1B1B;
}
.siteHeader .container {
  margin: 0px auto;
}
a.navbar-brand {
  max-width: 242px;
}
.navbar-brand img {
  width: 100%;
}
header.siteHeader .bg-body-tertiary {
  background-color: transparent !important;
  padding: 0px;
}
.siteHeader div#basic-navbar-nav .rightNav {
  margin-left: auto;
  align-items: center;
  gap: 40px;
  width: 100%;
  justify-content: center;
}
.siteHeader .rightNav a {
  display: block;
  color: #FBF0C2;
  text-decoration: none;
  font-family: Roboto-Medium;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0px;
}

ul.leftUl {
  gap: 70px;
}
ul.rightUl li {
  margin-left: 50px;
}
ul.rightUl li.headerBtn {
  margin-left: 0px;
}
/* Dropdown menu */
nav.headerNav ul li ul {
  display: none;
  position: absolute;
  background-color: #f9f9f9; /* Background color of the dropdown menu */
  min-width: 160px;
  z-index: 1;
}
nav.headerNav ul li:hover ul {
  display: block;
}
nav.headerNav ul li ul li {
  float: none;
}
nav.headerNav ul li ul li a {
  padding: 10px 15px;
}
.headerBtn button.btn {
  padding: 37px 35px;
  border: 0px;
  border-radius: 0px;
  color: #1B1B1B !important;
  font-family: Thunder-SemiBoldLC;
  font-size: 32px;
  line-height: 30px;
  text-transform: uppercase;
  background-color: #FBF0C2 !important;
}
.siteHeader .row {
    align-items: center;
}
.secTitle h1 {
  font-size: 120px;
  color: #FFD452;
  line-height: 84px;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;
}
.secTitle p {
  color: #FBF0C2;
}

footer.siteFooter {
  background-color: #1B1B1B;
}
.footerTop {
  text-align: center;
  padding-top: 90px;
  padding-bottom: 90px;
}
.footerLogo img {
  max-width: 300px;
}
.footerNav ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  list-style: none;
  gap: 130px;
}
.footerNav ul li a {
  color: #FBF0C2;
  text-decoration: none;
  font-size: 18px;
  text-transform: uppercase;
}
.footerNav {
  padding: 65px 0px;
}
.footerSocials ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  list-style: none;
  justify-content: center;
  gap: 30px;
}
.footerBottom {
  border-top: 1px solid #fbf0c21a;
  padding: 30px 0px;
}
p.copyrightText {
  color: #FBF0C2;
  margin: 0px;
}
p.copyrightText a {
  color: #FBF0C2;
  text-decoration: none;
}
.termsAndPolicy ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  gap: 65px;
}
.termsAndPolicy ul li a {
  color: #FBF0C2;
  text-decoration: none;
}

.menu-button span {
  width: 30px;
  display: block;
  height: 4px;
  background-color: #1b1b1b;
  margin-bottom: 5px;
}

.innerPageBanner {
  position: relative;
}
.innerPageBanner img {
  width: 100%;
}
.innerPageBanner h2 {
  position: absolute;
  top: 100px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120px;
  margin: 0px;
}

.radioButton {
  border: 1px solid #333333;
  height: 88px;
  border-radius: 15px;
  margin-bottom: 32px;
}
.radioButton label {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  cursor: pointer;
}
.radioButton label .buttonLable {
  color: #FFD452;
  font-size: 18px;
  font-family: Roboto-Medium;
  display: flex;
  align-items: center;
  gap: 16px;
}
.radioButton label .buttonLable::before {
  content: '';
  width: 45px;
  height: 45px;
  background-color: #FBF0C2;
  display: block;
  border-radius: 5px;
}
.radioButton input {
  display: none;
}
.form__radio-button {
  height: 25px;
  width: 25px;
  border: 2px solid #333333;
  border-radius: 50%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.form__radio-button::after {
  content: "";
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transition: opacity 0.1s;
  background-color: #FFD452;
  opacity: 0;
}
.radioButton input:checked + label .form__radio-button:after {
  opacity: 1;
}

.connectBtn {
  background-color: #ffd452;
  border: 0;
  border-radius: 0;
  color: #1b1b1b;
  display: block;
  font-family: Thunder-SemiBoldLC;
  font-size: 44px;
  margin-top: 55px;
  padding-top: 15px;
  text-transform: uppercase;
  width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {
  .siteHeader .rightNav a {
    font-size: 15px;
    color: #1b1b1b;
    border-bottom: 1px solid #1B1B1B;
    width: 100%;
    padding-bottom: 5px !important;
  }

  .siteHeader div#basic-navbar-nav .rightNav {
    gap: 10px;
    background: rgb(254, 213, 85);
    background: linear-gradient(90deg, rgba(254, 213, 85, 1) 0%, rgba(254, 218, 100, 1) 50%, rgba(240, 198, 73, 1) 100%);
    padding: 20px;
  }
  .siteHeader .rightNav a:last-child {
    border-bottom: 0px;
  }
  .headerBtn button.btn {
    padding: 20px 12px 15px;
    font-size: 25px;
  }
  .siteHeader .navbar-toggler {
    box-shadow: none;
    border-color: #1b1b1b;
    background-color: #ffd452;
  }
  a.navbar-brand {
    max-width: 150px;
  }
  .siteHeader .container a img {
      max-width: 180px;
  }


  .secTitle h1 {
    font-size: 80px;
    line-height: 55px;
  }
  .secTitle p {
    font-size: 14px;
  }
  .footerNav ul {
    display: block;
  }
  .ourProductSec .productBox .productBoxInner {
    display: block;
  }
  p.copyrightText {
    font-size: 13px;
    text-align: center;
  }
  .termsAndPolicy ul {
    gap: 35px;
    font-size: 13px;
    margin-top: 10px;
    justify-content: center;
  }
  .footerBottom {
    padding: 10px 0px;
  } 
  .footerTop {
    padding-top: 60px;
    padding-bottom: 70px;
  }
  .productBoxInfo h3 {
    font-size: 50px;
    line-height: 49px;
  }
  .ourProductSec .productBoxInfo p {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .ourProductSec .productBoxInfo {
    padding: 20px;
  }
  .productBoxImg {
    margin-bottom: 20px;
  }
  .priceAndBuy span.productPrice {
    font-size: 35px;
  }
  .priceAndBuy button.productBuyBtn {
    font-size: 25px;
    padding: 20px;
    line-height: 20px;
    height: 55px;
  }
  ul.membersTree {
    margin-top: 20px;
  }
  .footerNav ul li a {
    font-size: 15px;
    margin-bottom: 10px;
    display: inline-block;
  }
  li.complianBtn {
      font-size: 15px !important;
      margin-bottom: 10px;
      display: inline-block;
  }

  .innerPageBanner {
    background: rgb(254, 213, 85);
    background: linear-gradient(90deg, rgba(254, 213, 85, 1) 0%, rgba(254, 218, 100, 1) 50%, rgba(240, 198, 73, 1) 100%);
    height: 250px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .innerPageBanner img {
    display: none;
  }
  .innerPageBanner h2 {
    font-size: 38px;
    top: 100px;
    text-align: center;
    line-height: normal;
  }


  /* Complian CSS */


  .complianPopup .modal-dialog .modal-content {
    padding: 24px !important;
  }
  .complianPopup .modal-dialog .modal-title.h4 {
      margin: 30px auto 15px;
      font-size: 35px;
      line-height: 50px;
  }
  .complianPopup .modal-dialog .modal-body {
      margin: 20px 0px !important;
  }
  .complianPopup textarea {
      height: 100px;
  }
  .complianPopup button {
      padding: 16px 20px 8px;
      font-size: 30px !important;
      line-height: normal;
  }
  .complianPopup .modal-dialog button.btn-close {
      font-size: 16px !important;
  }
  .complianPopup .modal-dialog {
    width: 100%;
    max-width: 500px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  a.navbar-brand {
    max-width: 160px;
  }
  .siteHeader .rightNav a {
    font-size: 15px;
    color: #1b1b1b;
    border-bottom: 1px solid #1B1B1B;
    width: 100%;
    padding-bottom: 5px !important;
  }
  .siteHeader div#basic-navbar-nav .rightNav {
    gap: 10px;
    background: rgb(254, 213, 85);
    background: linear-gradient(90deg, rgba(254, 213, 85, 1) 0%, rgba(254, 218, 100, 1) 50%, rgba(240, 198, 73, 1) 100%);
    padding: 20px;
  }
  .siteHeader .rightNav a:last-child {
    border-bottom: 0px;
  }
  .headerBtn button.btn {
    padding: 20px 25px 15px;
    font-size: 25px;
  }
  .siteHeader .navbar-toggler {
    box-shadow: none;
    border-color: #1b1b1b;
    background-color: #ffd452;
  }

  .footerNav ul {
    gap: 20px;
  }
  .footerNav ul li a {
    font-size: 14px;
  }
  li.complianBtn {
      font-size: 14px!important;
  }
  p.copyrightText {
    font-size: 13px;
  }
  .termsAndPolicy ul li a {
    font-size: 13px;
  }
  .termsAndPolicy ul {
    gap: 20px;
  }
  .footerBottom {
    padding: 10px 0px;
  }
  .footerTop {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .secTitle h1 {
    font-size: 100px;
    line-height: 70px;
  }
  .innerPageBanner h2 {
    top: 100px;
    font-size: 62px;
  }


  /* Complian CSS */


  .complianPopup .modal-dialog .modal-content {
    padding: 30px;
  }
  .complianPopup .modal-dialog .modal-title.h4 {
      margin: 30px auto 15px;
      font-size: 60px;
      line-height: 50px;
  }
  .complianPopup .modal-dialog .modal-body {
      margin: 20px 0px;
  }
  .complianPopup textarea {
      height: 100px;
  }
  .complianPopup button {
      padding: 16px 20px 8px;
      font-size: 30px !important;
      line-height: normal;
  }
  .complianPopup .modal-dialog button.btn-close {
      font-size: 16px !important;
  }
  

}

@media (min-width: 992px) and (max-width: 1199px) {
  a.navbar-brand {
    max-width: 150px;
  }
  .siteHeader div#basic-navbar-nav .rightNav {
    gap: 17px;
  }
  .siteHeader .rightNav a {
    font-size: 13px;
  }
  .sgNav .dropdown button {
      font-size: 13px !important;
      padding-top: 0px !important;
  }
  .headerBtn button.btn {
    padding: 20px 25px;
    font-size: 23px;
    line-height: 25px;
  }
  .RightMenu {
    display: flex;
    align-items: center;
    gap: 17px;
  }
  .secTitle h1 {
    font-size: 90px;
    line-height: 63px;
  }
  .footerNav ul {
    gap: 50px;
  }
  .footerNav ul li a {
    font-size: 15px;
  }
  p.copyrightText {
    font-size: 14px;
  }
  .termsAndPolicy ul li a {
    font-size: 14px;
  }
  .termsAndPolicy ul {
    gap: 25px;
  }
  .footerBottom {
    padding: 10px 0px;
  }
  .footerTop {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .innerPageBanner h2 {
    top: 100px;
    font-size: 80px;
  }
  li.complianBtn {
      font-size: 15px!important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -116px !important;
        top: 45px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .siteHeader div#basic-navbar-nav .rightNav {
    gap: 23px;
  }
  .siteHeader .rightNav a {
    font-size: 15px;
  }
  .siteHeader .container > a img {
      max-width: 180px;
  }
  .RightMenu {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .headerBtn button.btn {
    padding: 33px 25px;
    font-size: 25px;
    line-height: 25px;
  }
  .secTitle h1 {
    font-size: 100px;
    line-height: 70px;
  }
  .footerNav ul {
    gap: 50px;
  }
  .footerBottom {
    padding: 10px 0px;
  }
  .innerPageBanner h2 {
    font-size: 100px;
  }


  .navbar-expand-lg .navbar-nav .dropdown-menu {
      top: 46px !important;
  }
  .dropdown-menu .logOutBtn button {
      font-size: 15px !important;
  }


  /* Complian CSS */


  .complianPopup .modal-dialog .modal-content {
    padding: 30px;
  }
  .complianPopup .modal-dialog .modal-title.h4 {
      margin: 30px auto 15px;
      font-size: 60px;
      line-height: 50px;
  }
  .complianPopup .modal-dialog .modal-body {
      margin: 20px 0px;
  }
  .complianPopup textarea {
      height: 100px;
  }
  .complianPopup button {
      padding: 16px 20px 8px;
      font-size: 30px !important;
      line-height: normal;
  }
  .complianPopup .modal-dialog button.btn-close {
      font-size: 16px !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -116px;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .siteHeader div#basic-navbar-nav .rightNav {
    gap: 25px;
  }
  .siteHeader .rightNav a {
    font-size: 16px;
  }
  .headerBtn button.btn {
    padding: 32px 25px;
    font-size: 25px;
  }

  .footerNav ul {
      gap: 80px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -116px;
  }
  .RightMenu {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}



/* Complian Popup CSS */


li.complianBtn {
  cursor: pointer;
    color: #FBF0C2;
    text-decoration: none;
    font-size: 18px;
    text-transform: uppercase;
}
.complianPopup textarea::placeholder {
    color: #fbf0c27a;
}
.complianPopup textarea {
    background-color: transparent;
    color: #FBF0C2;
    border: 2px solid #333333;
    padding: 28px 30px;
    border-radius: 15px;
    box-shadow: none;
    resize: none;
    height: 130px;
}
.complianPopup .modal-dialog .modal-body input {
    margin-bottom: 30px;
}
.complianPopup .modal-dialog {
    width: 100%;
}
.complianPopup .modal-dialog .modal-content {
    padding: 44px;
}
.complianPopup .modal-dialog input {
    height: 52px;
}
.complianPopup .modal-dialog .modal-body {
    margin: 30px 0px ;
}
.complianPopup textarea:focus {
    box-shadow: none;
    background-color: transparent;
    border-color: #333333;
    color: #FBF0C2;
}












/* DropDown CSS */


#dropdown-basic {
    background-color: transparent !important;
    border: 0px;
    padding: 0px;
}
.dropdown-menu {
    background-color: #1b1b1b;
}
.dropdown-menu .logOutBtn {
    padding-top: 0px !important;
    margin-top: 0px !important;
}
.siteHeader .dropdown-menu > a {
    text-align: center;
    padding: 0px 0px 10px !important;
    display: block !important;
}
.dropdown-menu .logOutBtn button {
    background-color: transparent;
    color: #FBF0C2;
    text-decoration: none;
    font-family: Roboto-Medium;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0px;
}


@media (min-width: 320px) and (max-width: 991px) {


  .sgNav {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch !important;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .sgNav .dropdown {
    width: 100% !important;
    max-width: 73px;
    display: flex;
    background-color: #000;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .RightMenu {
    width: 100%;
  }
  .sgNav a.headerBtn {
    width: auto !important;
    display: inline-block;
  }
  .sgNav a.headerBtn {
    padding-bottom: 0px !important;
  }
  .sgNav .dropdown .dropdown-menu {
    position: absolute;
  }
  .sgNav .dropdown .dropdown-menu a {
    color: #FBF0C2;
        padding: 8px 0px !important;
  }
  .logOutBtn button {
      font-size: 28px !important;
      padding-top: 10px !important;
  }
  .sgNav .dropdown button {
      font-size: 15px !important;
      padding-top: 0px !important;
  }


}
@media (min-width: 992px) and (max-width: 1199px) {

  .headerBtn button.btn {
    font-size: 18px !important;
        padding: 23px 12px !important;
  }
  .siteHeader .container > a {
    width: 100%;
    max-width: 180px;
  }
  .siteHeader .container > a img {
      width: 100%;
  }
  .siteHeader div#basic-navbar-nav .rightNav {
    gap: 14px !important;
  }
  .sgNav .dropdown img {
      width: 30px;
      height: 30px;
  }

  /* Complian CSS */


  .complianPopup .modal-dialog .modal-content {
    padding: 30px;
  }
  .complianPopup .modal-dialog .modal-title.h4 {
      margin: 30px auto 15px;
      font-size: 60px;
      line-height: 50px;
  }
  .complianPopup .modal-dialog .modal-body {
      margin: 20px 0px;
  }
  .complianPopup textarea {
      height: 100px;
  }
  .complianPopup button {
      padding: 16px 20px 8px;
      font-size: 30px !important;
      line-height: normal;
  }
  .complianPopup .modal-dialog button.btn-close {
      font-size: 16px !important;
  }
}



.navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -116px;
    top: 58px;
}

@media (min-width: 1700px) {
  
.siteHeader div#basic-navbar-nav .rightNav {
  margin-left: auto;
  align-items: center;
  gap: 40px;
  width: 100%;
  justify-content: center;
  display: block;
  text-align: center;
}
.siteHeader .rightNav a {
  display: inline-block;
  color: #FBF0C2;
  text-decoration: none;
  font-family: Roboto-Medium;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0px 25px;
}
.RightMenu {
  float: right;
}
.siteHeader .rightNav a:nth-child(1) {
  padding-left: 0px;
  position: relative;
}
.siteHeader .rightNav a:last-child,
.siteHeader .rightNav a:nth-child(6) {
  padding-right: 0px;
}
.RightMenu a:first-child::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #FBF0C2;
  position: absolute;
  right: 0px;
  opacity: .5;
}
.RightMenu .dropdown {
  display: inline-block;
  padding-left: 20px;
}

}