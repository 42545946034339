.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* halka transparent background */
    display: flex;
    flex-direction: column; /* vertical alignment ke liye */
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
.loading-overlay h5{
    color: #fff;
}
.spinners {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.loader-text {
    margin-top: 20px; /* thoda space spinner aur text ke beech */
    color: white; /* text color white */
  }