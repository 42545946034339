.SevenLevels {
    background-image: url('../../images/7_levels_sec_bg.png');
    background-color: #1B1B1B;
    padding: 120px 0px;
    background-size: 100% 100%;
    position: relative;
}
.SevenLevels .secTitle h1 {
    line-height: 100px;
    margin-bottom: 80px;
}
.SevenLevelsSubText {
    max-width: 760px;
    margin: 0px auto;
    padding-bottom: 100px;
}
.SevenLevelsSubText h2 {
    color: #FFD452;
    font-size: 72px;
    text-align: center;
}
.SevenLevelsSubText p {
    font-size: 15px;
}
.LevelBox h3 {
    color: #FFD452;
    font-size: 50px;
    text-align: center;
    background: rgb(62, 61, 56);
    background: linear-gradient(90deg, rgba(62, 61, 56, 1) 10%, rgba(40, 40, 40, 1) 50%, rgba(75, 68, 47, 1) 90%);
    border-radius: 18px;
    border: 1px solid #292929;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}
.LevelBox p {
    color: #FBF0C2;
    text-align: center;
    padding: 0px 16px;
    margin: 24px 0px 20px;
}
.LevelBox {
    background-image: url('../../images/level_box_bg.png');
    background-size: 100% 100%;
    height: 100%;
    min-height: 227px;
    padding: 5px;
}
.SevenLevels .col-md-4 {
    margin-bottom: 24px;
}
.PackegAmount h2 {
    color: #FFD452;
    font-size: 72px;
    text-align: center;
}
.PackegAmount {
    margin-top: 70px;
    margin-bottom: 20px;
}

.SevenLevelsTable {
    margin: 0px auto;
    border-radius: 30px;
    background-color: #1b1b1b59;
    box-shadow: 0px 3px 20px #0000007a;
}
.SevenLevelsTable table {
    table-layout: fixed;
}
.SevenLevelsTable .table>thead {
    background-color: #FFD452;
}
.SevenLevelsTable .table>:not(caption)>*>* {
    background-color: transparent;
    padding: 16px 32px;
    border-color: #5757574d !important;
    white-space: nowrap;
}
.SevenLevelsTable .table>thead tr th:first-child,
.SevenLevelsTable .table>thead tr th:last-child,
.SevenLevelsTable .table>tbody tr td:first-child,
.SevenLevelsTable .table>tbody tr td:last-child {
    border: 0px;
}
.SevenLevelsTable .table>thead tr {
    border-top: 0px;
}
.SevenLevelsTable .table>tbody tr:last-child {
    border-bottom: 0px;
}
.SevenLevelsTable .table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0;
    border-color: #5757574d !important;
}
.SevenLevelsTable .table>thead tr th {
    padding: 24px 32px;
    font-size: 20px;
    text-align: center;
}
.SevenLevelsTable .table>tbody tr td {
    font-size: 18px;
    color: #FBF0C2;
    text-align: center;
}
.SevenLevelsTable::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #000;
}
.SevenLevelsTable::-webkit-scrollbar-thumb {
    background-color: #ffdc64;
}

@media (min-width: 320px) and (max-width: 767px) {
    .SevenLevels .secTitle h1 {
        line-height: 50px;
        margin-bottom: 50px;
        font-size: 50px;
    }
    .SevenLevelsSubText h2 {
        font-size: 45px;
    }
    .LevelBox h3 {
        font-size: 35px;
    }
    .SevenLevelsTable table {
        table-layout: auto;
    }
    .PackegAmount h2 {
        font-size: 45px;
    }
    .SevenLevelsSubText {
        padding-bottom: 50px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .SevenLevels .col-md-4 {
        width: 50%;
    }
    .SevenLevels .col-md-4:empty {
        width: 25%;
    }
    .SevenLevels .secTitle h1 {
        line-height: 80px;
        margin-bottom: 60px;
        font-size: 80px;
    }
    .SevenLevelsSubText {
        padding-bottom: 50px;
    }
    .LevelBox h3 {
        font-size: 35px;
    }
    .SevenLevelsSubText h2, .PackegAmount h2 {
        font-size: 55px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .SevenLevels .secTitle h1 {
        line-height: 80px;
        margin-bottom: 80px;
        font-size: 90px;
    }
}