.profileSec {
    background-image: url('../../images/profile-bg.webp');
    background-color: #1B1B1B;
    padding: 100px 0px 0px;
    margin-top: -100px;
    background-size: cover;
}
.userProfileImg img {
    max-width: 100px;
    border-radius: 10px;
}
.loginUserName {
    display: flex;
    gap: 32px;
    align-items: center;
    border-bottom: 1px solid #fbf0c21f;
    padding-bottom: 40px;
    margin-bottom: 40px;
}
.userName p {
    color: #FBF0C2;
    font-size: 18px;
    margin-bottom: 5px;
}
.userName h3 {
    color: #FFD452;
    margin: 0px;
    font-size: 48px;
}
.profileSec .container > .row {
    margin: 0px;
}
.profileSec .row .column-three,
.profileSec .row .column-nine {
    padding: 0px;
}
.profileSec .row .column-three {
    border-right: 1px solid #fbf0c21f;
    padding-top: 120px;
}
.column-three .nav-pills .nav-link {
    font-size: 24px;
    text-transform: capitalize;
    font-family: Roboto-Medium;
    color: #FBF0C2;
    padding: 24px;
    border-radius: 0px;
}
.column-three .nav-pills .nav-link.active {
    background-color: #FFD452;
    color: #1B1B1B;
}
.logOutBtn {
    padding-top: 40px;
    margin-top: 40px;
}
.logOutBtn button {
    text-transform: uppercase;
    display: block;
    width: 100%;
    font-size: 44px;
    background-color: #FFD452;
    border: 0px;
    font-family: 'Thunder-SemiBoldLC';
    color: #1B1B1B;
    padding-top: 15px;
    border-radius: 0px;
}
.tabTitle h4 {
    font-family: Roboto-Bold;
    text-transform: uppercase;
    font-size: 48px;
    color: #FBF0C2;
    border-bottom: 1px solid #fbf0c21f;
    padding-bottom: 55px;
}
.profileSec .row .column-nine {
    padding-left: 80px;
    padding-bottom: 120px;
}
.tabTitle {
    padding-top: 150px;
}
.tabActiveIcon {
    display: none;
}
.column-three .nav-pills .nav-link.active .tabActiveIcon {
    display: inline-block;
}
.column-three .nav-pills .nav-link.active .tabIcon {
    display: none;
}
.column-three .nav-pills .nav-link span {
    margin-left: 15px;
}
.personalInfoForm {
    padding-top: 30px;
}
.personalForm > label {
    display: block;
    color: #FFD452;
    font-size: 18px;
    margin-bottom: 10px;
}
.personalForm input {
    border: 1px solid #fbf0c21f !important;
    height: 88px;
    color: #FBF0C2 !important;
    background-color: transparent !important;
    border-radius: 16px;
    padding: 0px 32px;
    font-family: Roboto-Light;
    box-shadow: none !important;
    margin-bottom: 40px;
    width: 100%;
}
.personalForm input::placeholder {
    color: #FBF0C2 !important;
}
.halfColRow {
    display: flex;
    gap: 25px;
}
.halfCol {
    width: 50%;
}
.personalForm input[type=number]::-webkit-outer-spin-button, 
.personalForm input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.personalForm .radioButton {
    border: 0px;
    height: auto;
}
.personalForm .radioButton label .buttonLable::before {
    content: none;
}
.personalForm .radioButton label {
    padding: 0px;
    justify-content: flex-start;
    gap: 15px;
}
.personalInfoForm {
    padding-top: 30px;
    max-width: 600px;
}
.personalForm input[type=date]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.innerTabNav.nav-tabs .nav-link {
    padding: 0px;
    font-size: 28px;
    text-transform: uppercase;
    color: #FBF0C2;
    border: 0px;
}
.innerTabNav.nav-tabs .nav-link.active {
    background: transparent;
    border: 0px;
    color: #FFD452;
}
.innerTabNav {
    border: 0px !important;
    gap: 125px;
    margin-top: 45px;
    margin-bottom: 100px !important;
}
.innerTabNav li.nav-item {
    position: relative;
}
.innerTabNav li.nav-item:first-child:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 45px;
    background-color: #fbf0c21f;
    top: 0px;
    right: -70px;
}
.kingdomBox {
    margin-bottom: 30px;
}
.kingdomBoxHeader {
    position: relative;
}
.kingdomBoxHeader > a > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.kingdomBoxHeader > a {
    display: block;
}
.blur-mg{
    filter: blur(8px);
}
.kingdomBoxHeader > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.refeeralCode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffd452;
    position: initial;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.kingdomBoxHeader > img {
    width: 100%;
}
.refeeralCode span {
    color: #1B1B1B;
    font-size: 20px;
    font-family: Roboto-Bold;
    padding-left: 32px;
}
.refeeralCode button {
    border: 0px;
    padding: 0px;
}
.kingdomBoxBody {
    background-color: #242424ba;
    padding: 24px 32px;
}
.transactionHistory p {
    color: #FBF0C2;
    font-size: 15px;
    margin-bottom: 0px;
}
.kingdomPrice-Btn button{
    background: red;
    border: none;
    font-family: Thunder-SemiBoldLC;
    font-size: 22px;
    color: #fff;
    padding: 2px 18px;
}
.kingdomBoxBody h3 > a ,.kingdomBoxBody h3 {
    color: #FFD452;
    font-size: 66px;
    line-height: 45px;
    text-decoration: none;
}
.kingdomBoxBody p {
    color: #FBF0C2;
    font-size: 15px;
    margin-bottom: 0px;
}
.kingdomBoxBody {
    background-color: #242424ba;
    padding: 35px 41px 24px 30px;
    border-bottom: 1px solid #fbf0c21f;
}
.kingdomBoxFooter {
    background-color: #2b2b2b8f;
    padding: 24px 32px;
}
.kingdomPrice-Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.kingdomPrice-Btn span {
    color: #FBF0C2;
    font-size: 56px;
    font-family: Thunder-BoldLC;
    line-height: 55px;
}
.kingdomPrice-Btn a {
    background-color: #FBF0C2;
    color: #1B1B1B;
    text-decoration: none;
    font-family: Thunder-SemiBoldLC;
    font-size: 32px;
    text-transform: uppercase;
    padding: 16px 24px 10px;
    line-height: 25px;
}
.kingdomBoxFooter ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    gap: 15px;
    margin-top: 20px;
}
.kingdomBoxFooter ul img {
    border-radius: 50px;
    border: 2px solid #FBF0C2;
    width: 35px;
    height: 35px;
}
.transactionHistory a {
    color: #FFD452;
    font-size: 18px;
    display: block;
    text-align: center;
    margin-top: 24px;
}

.kingdomDetailHeader {
    display: flex;
    justify-content: space-between;
    background-color: #21212136;
    align-items: center;
    padding: 60px 20px;
}
.kingdomHeaderColOne h3 {
    color: #FFD452;
    font-size: 124px;
    line-height: 86px;
    max-width: 330px;
}
.kingdomHeaderColTwo p {
    color: #FBF0C2;
    font-size: 18px;
    max-width: 285px;
}
.kingdomHeaderColThree h3 {
    color: #FBF0C2;
    font-size: 80px;
    line-height: 70px;
}
.kingdomHeaderColThree {
    text-align: right;
}
.kingdomHeaderColThree button {
    background-color: #5ACC00;
    color: #1B1B1B;
    text-decoration: none;
    font-family: Thunder-SemiBoldLC;
    font-size: 32px;
    text-transform: uppercase;
    padding: 20px 42px 13px;
    line-height: 25px;
    border: 0px;
}
.kingdomHeaderColThree a {
    color: #FFD452;
    font-size: 18px;
    display: block;
    margin-top: 24px;
}
.treeMain {
    margin-top: 120px;
}
.treeOne {
    max-width: 350px;
    margin: 0px auto;
}
.treeOne h4,
.treeRow h4 {
    color: #FFD452;
    text-align: center;
    font-size: 40px;
    font-family: Roboto-Medium;
    text-transform: uppercase;
    margin-top: 20px;
}
.treeMain .treeRow p {
    margin-bottom: 0px;
    margin-top: 0px !important;
}
.treeRow h4 {
    margin-bottom: 0px;
}
.treeOne p,
.treeRow p {
    color: #FBF0C2;
    text-align: center;
    font-size: 18px;
    margin-top: 20px !important;
     margin-top: 0px;

}
.treeFrame {
    text-align: center;
    margin-top: 40px;
}
.treeRow {
    display: flex;
    justify-content: space-between;
}
.profileSec .container {
    margin: 0px auto;
}

@media (min-width: 320px) and (max-width: 767px) {
    .userName h3 {
        font-size: 38px;
    }
    .loginUserName {
        gap: 22px;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .profileSec .row .column-three {
        border-right: 0px;
        padding-top: 60px;
    }
    .profileSec .row .column-nine {
        padding-left: 0px;
        padding-bottom: 90px;
    }
    .tabTitle h4 {
        font-size: 35px;
        padding-bottom: 30px;
    }
    .tabTitle {
        padding-top: 70px;
    }
    .column-three .nav-pills .nav-link {
        font-size: 19px;
        padding: 17px;
    }
    .column-three .nav-pills .nav-link span {
        margin-left: 10px;
    }
    .personalForm input {
        height: 60px;
        padding: 0px 20px;
        margin-bottom: 30px;
    }
    .halfCol {
        width: 100%;
    }
    .halfColRow {
        display: block;
    }
    .column-three .nav-pills .nav-link img {
        max-width: 25px;
    }
    .innerTabNav {
        gap: 25px;
        margin-top: 35px;
        margin-bottom: 50px !important;
    }
    .innerTabNav.nav-tabs .nav-link {
        font-size: 15px;
    }
    .innerTabNav li.nav-item:first-child:after {
        height: 23px;
        top: 0px;
        right: -13px;
    }
    .refeeralCode span {
        font-size: 15px;
    }
    .kingdomPrice-Btn span {
        font-size: 40px;
    }
    .kingdomDetailHeader {
        display: block;
    }
    .treeFrame {
        display: none;
    }
    .treeRow {
        display: block;
    }
    .kingdomHeaderColOne h3 {
        font-size: 85px;
        line-height: 60px;
    }
    .kingdomHeaderColThree h3 {
        font-size: 60px;
        line-height: 50px;
        margin-top: 50px;
    }
    .treeOne p, .treeRow p {
        margin-top: 0px !important;
        margin-bottom: 40px !important;
        font-size: 16px;
    }
    .treeOne h4, .treeRow h4 {
        margin-bottom: 0px;
        font-size: 30px;
    }


    .profileSec .container > .row > .col-sm-3,
    .profileSec .container > .row > .col-sm-9 {
        width: 100% !important;
    }

    .treeMain p {
        padding: 10px 30px !important;
    }
    .treeMain {
        margin-top: 60px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .userProfileImg img {
        max-width: 50px;
    }
    .userProfileImg img:first-child {
        width: 70px !important;
    }
    .userProfileImg img:first-child {
        height: 70px !important;
        max-width: 70px !important;
    }
    img.iconprofile {
        top: 20px !important;
        left: 20px !important;
    }
    .loginUserName {
        gap: 10px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .userName h3 {
        font-size: 27px;
    }
    .userName p {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .column-three .nav-pills .nav-link {
        font-size: 14px;
        padding: 10px;
    }
    .column-three .nav-pills .nav-link span {
        margin-left: 5px;
    }
    .column-three .nav-pills .nav-link img {
        max-width: 25px;
    }
    .logOutBtn {
        padding-top: 20px;
        margin-top: 20px;
    }
    .logOutBtn button {
        font-size: 30px;
        padding-top: 10px;
    }
    .tabTitle h4 {
        font-size: 35px;
        padding-bottom: 40px;
    }
    .tabTitle {
        padding-top: 115px;
    }
    .profileSec .row .column-nine {
        padding-left: 40px;
        padding-bottom: 100px;
    }
    .personalForm input {
        height: 80px;
        padding: 0px 22px;
        margin-bottom: 30px;
    }
    .innerTabNav {
        gap: 40px;
        margin-bottom: 40px !important;
    }
    .innerTabNav.nav-tabs .nav-link {
        font-size: 20px;
    }
    .innerTabNav li.nav-item:first-child:after {
        height: 30px;
        right: -20px;
    }
    #left-tabs-example-tabpane-second .col-md-4 {
        width: 50%;
    }
    .refeeralCode span {
        font-size: 15px;
        padding-left: 15px;
    }
    .kingdomBoxBody {
        padding: 35px 20px 24px 20px;
    }
    .kingdomBoxFooter {
        padding: 24px 20px;
    }
    .kingdomPrice-Btn span {
        font-size: 33px;
    }
    .kingdomPrice-Btn a {
        font-size: 25px;
        padding: 15px 20px 5px;
        line-height: 25px;
    }
    .transactionHistory a {
        font-size: 15px;
        margin-top: 20px;
    }
    .kingdomDetailHeader {
        display: block;
    }
    .treeFrame img {
        width: 350px;
    }
    .treeOne h4, .treeRow h4 {
        font-size: 20px;
    }
    .treeOne p, .treeRow p {
        font-size: 14px;
    }
    .treeRow {
        gap: 20px;
    }
    .kingdomHeaderColOne h3 {
        font-size: 85px;
        line-height: 60px;
    }
    .kingdomHeaderColThree h3 {
        font-size: 60px;
        line-height: 50px;
        margin-top: 50px;
    }
    .treeOne {
        max-width: 300px;
    }

    .profileSec .tab-content .col-md-4 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .loginUserName {
        gap: 20px;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .userName h3 {
        font-size: 30px;
    }
    .userName p {
        font-size: 16px;
        margin-bottom: 0px;
    }
    .userProfileImg img {
        /*max-width: 70px;
        object-fit: cover;
        object-position: center;
        height: auto !important;*/
    }.userProfileImg img:first-child {
        height: 70px !important;
        width: 70px !important;
    }
    img.iconprofile {
                left: 25px !important;
        top: 25px !important;
        width: 25px;
        height: auto !important;
    }
    .sgProfile .iconWrapper img {
        width: 35px;
        height: 35px;
    }
    .logOutBtn button {
        font-size: 30px;
        padding-top: 10px;
    }
    .column-three .nav-pills .nav-link img {
        max-width: 30px;
    }
    .column-three .nav-pills .nav-link {
        font-size: 18px;
        padding: 20px;
    }
    .profileSec .row .column-nine {
        padding-left: 50px;
    }
    .tabTitle {
        padding-top: 105px;
    }
    #left-tabs-example-tabpane-second .col-md-4 {
        width: 50%;
    }
    .refeeralCode span {
        font-size: 15px;
        padding-left: 15px;
    }
    .kingdomBoxBody {
        padding: 35px 20px 24px 20px;
    }
    .kingdomBoxFooter {
        padding: 24px 20px;
    }
    .kingdomPrice-Btn span {
        font-size: 33px;
    }
    .kingdomPrice-Btn a {
        font-size: 25px;
        padding: 15px 20px 5px;
        line-height: 25px;
    }
    .profileSec .tab-content .col-md-4 {
        width: 50% !important;
    }
    .transactionHistory a {
        font-size: 15px;
        margin-top: 20px;
    }
    .kingdomHeaderColOne h3 {
        font-size: 60px;
        line-height: 40px;
        max-width: 140px;
    }
    .kingdomHeaderColTwo p {
        font-size: 14px;
        max-width: 220px;
    }
    .kingdomHeaderColThree h3 {
        font-size: 50px;
        line-height: 40px;
    }
    .kingdomHeaderColThree a {
        font-size: 11px;
    }
    .kingdomHeaderColThree button {
        font-size: 25px;
        padding: 15px 30px 7px;
        line-height: 25px;
    }
    .treeMain {
        margin-top: 20px;
    } 
    .treeRow {
        gap: 50px;
    }
    .treeFrame img {
        width: 480px;
    }
    .treeOne h4, .treeRow h4 {
        font-size: 30px;
    }
    .treeOne p, .treeRow p {
        font-size: 18px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .column-three .nav-pills .nav-link {
        font-size: 20px;
        padding: 20px;
    }
    .refeeralCode span {
        font-size: 12px;
        padding-left: 12px;
    }
    .kingdomBoxBody {
        padding: 35px 20px 24px 20px;
    }
    .kingdomBoxFooter {
        padding: 24px 20px;
    }
    .kingdomPrice-Btn span {
        font-size: 33px;
    }
    .kingdomPrice-Btn a {
        font-size: 25px;
        padding: 15px 20px 5px;
        line-height: 25px;
    }
    .transactionHistory a {
        font-size: 15px;
        margin-top: 20px;
    }
    .userName h3 {
        color: #FFD452;
        margin: 0px;
        font-size: 40px;
    }
    .userProfileImg img {
        max-width: 70px;
    }
    .userProfileImg img:first-child {
        height: 70px !important;
        max-width: 70px !important;
        width: 70px !important;
    }
    img.iconprofile {
        top: 21px !important;
        left: 20px !important;
    }
    .loginUserName {
        gap: 10px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .kingdomHeaderColOne h3 {
        font-size: 60px;
        line-height: 40px;
        max-width: 140px;
    }
    .kingdomHeaderColTwo p {
        font-size: 14px;
        max-width: 220px;
    }
    .kingdomHeaderColThree h3 {
        font-size: 50px;
        line-height: 40px;
    }
    .kingdomHeaderColThree a {
        font-size: 11px;
    }
    .kingdomHeaderColThree button {
        font-size: 25px;
        padding: 15px 30px 7px;
        line-height: 25px;
    }
    .treeMain {
        margin-top: 20px;
    } 
    .treeRow {
        gap: 50px;
    }
    .treeFrame img {
        width: 480px;
    }
    .treeOne h4, .treeRow h4 {
        font-size: 30px;
    }
    .treeOne p, .treeRow p {
        font-size: 18px;
        
    }
    .treeOne {
        max-width: 300px;
    }


    .logOutBtn button {
        font-size: 34px;
    }
}

@media (min-width: 1400px) and (max-width: 1699px) {
    .refeeralCode span {
        font-size: 15px;
        padding-left: 15px;
    }
    .kingdomBoxBody {
        padding: 35px 20px 24px 20px;
    }
    .kingdomBoxFooter {
        padding: 24px 20px;
    }
    .kingdomPrice-Btn span {
        font-size: 33px;
    }
    .kingdomPrice-Btn a {
        font-size: 25px;
        padding: 15px 20px 5px;
        line-height: 25px;
    }
    .transactionHistory a {
        font-size: 15px;
        margin-top: 20px;
    }
    .kingdomHeaderColOne h3 {
        font-size: 100px;
        line-height: 70px;
        max-width: 270px;
    }
    .kingdomHeaderColTwo p {
        font-size: 15px;
        max-width: 250px;
    }
    .kingdomHeaderColThree h3 {
        font-size: 60px;
        line-height: 50px;
    }
    .kingdomHeaderColThree button {
        font-size: 32px;
        padding: 20px 30px 13px;
        line-height: 25px;
    }
    .treeFrame img {
        width: 650px;
    }
    .treeOne h4, .treeRow h4 {
        font-size: 30px;
    }
    .treeOne p, .treeRow p {
        font-size: 20px;
        margin-top: 20px;
    }
}





/* Personal Information CSS */


.sgProfile {
    position: relative;
}
.sgProfile .iconWrapper {
    position: absolute;
    top: 40px;
    right: 0px;
}
.sgProfile .personalForm {
    width: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 40px;
}
.sgProfile .personalForm label {
    color: #FFD452;
    font-size: 18px;
    font-family: 'Roboto-Regular';
    line-height: 22px;
    margin-bottom: 16px;
}
.sgProfile .personalForm .sgLabel {
    width: 49%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 64px;
    background: linear-gradient(90deg, rgb(255 212 82 / 20%), #997F3100);
    padding: 16px 24px 16px;
    border-radius: 8px 0px 0px 8px;
    border-left: 2px solid #FBF0C2;
    max-width: 275px;
}
.sgProfile .personalForm .sgLabel .labelFileds {
    color: #FBF0C2;
    font-size: 18px;
    font-family: 'Roboto-Regular';
}



@media (min-width: 1200px) and (max-width: 1399px) {

    .sgProfile .personalForm {
        max-width: 600px;
    }
}




@media (min-width: 992px) and (max-width: 1199px) {

   .sgProfile .personalForm {
        max-width: 560px;
    }
    .sgProfile .personalForm .sgLabel {
        max-width: 265px;
        padding: 12px 20px 12px;
        margin-bottom: 50px;
    }
    .sgProfile .personalForm label {
        font-size: 16px;
        margin-bottom: 12px;
    }
    .sgProfile .personalForm .sgLabel .labelFileds {
        font-size: 16px;
    }
}


@media (min-width: 768px) and (max-width: 991px) {

    .sgProfile .personalForm {
        max-width: unset;
        padding-top: 80px;
    }
    .sgProfile .personalForm .sgLabel {
        margin-bottom: 32px;
        padding: 8px 16px 8px;
    }
    .sgProfile .iconWrapper {
        top: 12px;
    }
    .sgProfile .personalForm label {
        font-size: 14px;
    }
    .sgProfile .personalForm .sgLabel .labelFileds {
        font-size: 14px;
    }
    .sgProfile .iconWrapper img {
        max-width: 40px;
    }
}


@media (min-width: 320px) and (max-width: 767px) {

    .sgProfile .personalForm .sgLabel {
        width: 100%;
        margin-bottom: 32px;
        padding: 8px 16px 8px;
        max-width: unset;
    }
    .sgProfile .personalForm label {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .sgProfile .personalForm .sgLabel .labelFileds {
        font-size: 14px;
    }
    .sgProfile .personalForm {
        max-width: unset;
        padding-top: 70px;
    }
    .sgProfile .iconWrapper {
        top: 5px;
    }
}

img.iconprofile {
    position: absolute;
    top: 35px;
    left: 35px;
    font-size: 0px;
    filter: brightness(0.1);
}
.loginUserName {
    position: relative;
}
.userProfileImg img {
    border-radius: 4px;
}
.userProfileImg {
    position: relative;
}
.userProfileImg:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgb(255 212 82 / 35%);
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 4px;
}
.userProfileImg img:first-child{
    height: 100px;
    max-width: 100px !important;
    width: 100px;
    object-fit: cover;
    object-position: top;
}

.kingdomBoxBody a {
    text-decoration: none;
}
.treeMain p {
    color: #ffd452 !important;
    width: 100% !important;
}
.treeMain p a {
    color: #ffd452 !important;
}