/* src/components/UserNode.css */
.user-node {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  background-color: #f9f9f9;
  position: relative;
}

.user-info {
  margin-bottom: 10px;
}
.metaInfo button {
  background-color: #1B1B1B;
  border: 1px solid #ffd452;
  color: #ffd452;
  padding: 5px 20px;
  font-size: 15px;
  border-radius: 5px;
}
.user-children {
  margin-left: 20px;
}
/* src/components/UserTree.css */
.user-tree {

  margin: 20px;
}
.left_class{
  display: inline-block;
}
.user-tree{
  background: #fff;
}
.user-info{
  margin: 0px auto;
}
.user-bord{
  padding: 10px;
    border: 1px solid #535252;
    width: 200px;
    text-align: center;
   
    border-radius: 4px;
}
.user-children-30{
  display: inline-block;
}
.user-tree{
  text-align: center;
}
#mainContainer{
  background:Red;
  min-width:850px;
margin-top: 100px;
}
.containers{
  text-align:center;
  margin:10px .5%;
  padding:10px .5%;

  float:left;
  overflow:visible;
  position:relative;
}


.member{   
  position:relative;
  z-index:   1;
  cursor:default;
  border-bottom:solid 1px #fbf0c278;

}
.member:after{
  display: block;
  position: absolute;
  left: 50%;
  width: 1px;
  height: 25px;
  background: #fbf0c2;
  content: " ";
  bottom: 100%;
  opacity: .5;
}
.member:hover{
z-index:   2;
}
.metaInfo {
  background: #FBF0C2;
  padding: 5px;
  width: 100px;
  margin: 0px auto;
}
.member:hover > .metaInfo:first-child{
  display:block;   
}
.member > .metaInfo img{
width:50px;
height:50px; 
display:inline-block; 
padding:5px;
margin-right:5px;
  vertical-align:top;
border:solid 1px #aaa;
}
.metaInfo p {
  font-family: 'Roboto-Medium';
  color: #1B1B1B;
  font-size: 18px;
  margin: 5px 0px;
  text-transform: capitalize;
}
.metaInfo span {
  background-color: #ffd452;
  border: 1px solid #ffd452;
  color: #1B1B1B;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 5px;
  display: inline-block;
}