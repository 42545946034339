.ConnectWalletForm {
    padding: 65px;
    background-color: #212121;
    border-radius: 60px;
}
.ConnectWalletForm h3 {
    color: #FFD452;
    font-size: 96px;
    text-align: center;
    line-height: 90px;
}
.ConnectWalletForm p {
    color: #FBF0C2;
    text-align: center;
    margin-bottom: 55px;
}
.radioButton {
    border: 1px solid #333333;
    height: 88px;
    border-radius: 15px;
    margin-bottom: 32px;
}
.radioButton label {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    cursor: pointer;
}
.radioButton label .buttonLable {
    color: #FFD452;
    font-size: 18px;
    font-family: Roboto-Medium;
    display: flex;
    align-items: center;
    gap: 16px;
}
.radioButton label .buttonLable::before {
    content: '';
    width: 45px;
    height: 45px;
    background-color: #FBF0C2;
    display: block;
    border-radius: 5px;
}
.radioButton input {
    display: none;
}
.form__radio-button {
    height: 25px;
    width: 25px;
    border: 2px solid #333333;
    border-radius: 50%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .form__radio-button::after {
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    transition: opacity 0.1s;
    background-color: #FFD452;
    opacity: 0;
  }
.radioButton input:checked + label .form__radio-button:after {
    opacity: 1;
  }
  .connectBtn {
    text-transform: uppercase;
    display: block;
    width: 100%;
    font-size: 44px;
    background-color: #FFD452;
    border: 0px;
    font-family: 'Thunder-SemiBoldLC';
    color: #1B1B1B;
    padding-top: 15px;
    border-radius: 0px;
    margin-top: 55px;
  }
  p.walletPrivacyText {
    max-width: 500px;
    margin: 0px auto;
    padding-top: 25px;
}
p.walletPrivacyText a {
    font-family: Roboto-Medium;
    color: #FFD452;
    text-decoration: none;
}

@media (min-width: 320px) and (max-width: 767px) {
    .formBox {
        border-radius: 20px;
    }
    .ConnectWalletForm {
        padding: 50px 20px;
        border-radius: 20px;
    }
    .ConnectWalletForm h3 {
        font-size: 45px;
        line-height: 50px;
    }
    .ConnectWalletForm p {
        font-size: 14px;
    }
    button.connectBtn {
        font-size: 30px;
        padding-top: 10px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .ConnectWalletForm h3 {
        font-size: 86px;
        line-height: 80px;
    }
    .ConnectWalletForm p {
        font-size: 14px;
    }
    button.connectBtn {
        font-size: 35px;
    }
}