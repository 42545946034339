.newsletterHomeSec {
    background-image: url('../../images/newsletter-bg.webp');
    padding-top: 170px;
    padding-bottom: 120px;
    background-size: 100% 100%;
}
.newsletterHomeSec .container {
    max-width: 1330px;
}
.newsletterFields input[type="email"] {
    width: 100%;
    border: 0px;
    padding: 22px 20px;
    background-color: #141313;
    color: #FBF0C2;
    outline: none;
    font-size: 18px;
}
.newsletterFields {
    position: relative;
}
.newsletterFields input[type="email"]::placeholder {
    color: #FBF0C2;
}
button.SubscribeBtn {
    font-family: Thunder-SemiBoldLC;
    background-color: #FFD452;
    font-size: 32px;
    text-transform: uppercase;
    padding: 24px 32px;
    line-height: 30px;
    border: 0px;
    height: 70px;
    display: inline-block;
    color: #1B1B1B;
    text-decoration: none;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
}
.newsletterForm {
    margin-top: 50px;
}
.newsletterHomeSec .secTitle h1 {
    margin-bottom: 0px;
    max-width: 300px;
}
.newsletterHomeSec .row {
    align-items: flex-end;
}

@media (min-width: 320px) and (max-width: 767px) {
    .newsletterHomeSec {
        padding-top: 100px;
        padding-bottom: 80px;
        background-size: cover;
    }
    button.SubscribeBtn {
        font-size: 20px;
        padding: 24px 20px;
        line-height: 20px;
        height: 60px
    }
    .newsletterFields input[type="email"] {
        padding: 19px 20px;
        font-size: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .newsletterHomeSec {
        padding-top: 90px;
        padding-bottom: 90px;
        background-size: cover;
    }
    .newsletterHomeSec .row {
        align-items: center;
    }
    .secTitle p {
        font-size: 14px;
    }
    button.SubscribeBtn {
        font-size: 23px;
        padding: 24px 22px;
        line-height: 23px;
        height: 60px;
    }
    .newsletterFields input[type="email"] {
        padding: 20px 20px;
        font-size: 14px;
    }
}

@media (min-width: 992px) and (max-width: 1399px) {
    .newsletterHomeSec {
        padding-top: 90px;
        padding-bottom: 90px;
        background-size: cover;
    }
    button.SubscribeBtn {
        font-size: 23px;
        padding: 24px 22px;
    }
    .newsletterHomeSec .row {
        align-items: center;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .newsletterHomeSec .container {
        max-width: 1000px;
    }
}