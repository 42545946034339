.moneyBackSec {
    background-image: url('../../images/money-back-bg.webp');
    background-color: #1B1B1B;
    padding: 120px 0px;
    background-size: 100% 100%;
}
.moneyBackMain {
    display: flex;
    max-width: 1210px;
    margin: 0px auto;
}
.moneyBackImg img {
    max-width: 400px;
}
.moneyBackText h1 {
    font-size: 150px;
    line-height: 105px;
    color: #FFD452;
    margin-bottom: 20px;
    margin-top: 50px;
    max-width: 550px;
}
ul.moneyBackList {
    padding: 0px;
    margin-bottom: 50px;
    margin-left: 40px;
    list-style: none;
}
ul.moneyBackList li {
    color: #FBF0C2;
    font-size: 14px;
    margin-bottom: 12px;
    display: flex;
}
ul.moneyBackList li::before {
    content: url('../../images/check-box.webp');
    position: relative;
    top: 2px;
    margin-right: 12px;
}
a.registerBtn {
    font-family: Thunder-SemiBoldLC;
    background-color: #FFD452;
    font-size: 25px;
    text-transform: uppercase;
    padding: 24px 25px;
    line-height: 30px;
    border: 0px;
    height: 70px;
    display: inline-block;
    color: #1B1B1B;
    text-decoration: none;
    margin-left: 40px;
}
a.registerBtn::after {
    content: url('../../images/button-arrow.webp');
    position: relative;
    top: -3px;
    margin-left: 15px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .moneyBackMain {
        display: block;
    }
    .moneyBackText h1 {
        font-size: 80px;
        line-height: 55px;
    }
    .moneyBackImg img {
        width: 100%;
    }
    .moneyBackSec {
        padding: 62px 0px 68px;
        background-size: cover;
    }
    ul.moneyBackList {
        margin-bottom: 40px;
        margin-left: 0px;
    }
    a.registerBtn {
        font-size: 22px;
        padding: 18px 20px;
        line-height: 30px;
        height: 60px;
        margin-left: 0px;
    }
    a.registerBtn::after {
        top: 0px;
        margin-left: 12px;
    }
    ul.moneyBackList li {
        font-size: 14px;
    }
    .moneyBackImg {
        text-align: center;
    }
    .moneyBackImg img {
        max-width: 280px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .moneyBackMain {
        display: block;
        max-width: 1210px;
        margin: 0px auto;
    }
    .moneyBackText h1 {
        font-size: 100px;
        line-height: 70px;
        max-width: 400px;
    }
    ul.moneyBackList {
        margin-bottom: 40px;
        margin-left: 0px;
    }
    a.registerBtn {
        margin-left: 0px;
    }
    .moneyBackSec {
        padding: 90px 0px 80px;
        background-size: cover;
    }
    .moneyBackImg {
        text-align: center;
        max-width: 400px;
        margin: 0px auto;
    }
    .moneyBackImg img {
        width: 100%;
        max-width: 300px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .moneyBackText h1 {
        font-size: 130px;
        line-height: 90px;
    }
    .moneyBackSec {
        padding: 100px 0px 100px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .moneyBackText h1 {
        font-size: 150px;
        line-height: 105px;
    }
    .moneyBackSec {
        padding: 100px 0px 100px;
    }
}