.productBoxImg {
  width: 40%;
}
.productBoxImg img {
  width: 100%;
  border-radius: 30px;
}
.productBoxInfo h3 {
  color: #FBF0C2;
  font-size: 70px;
  line-height: 56px;
}
.ourProductSec .productBox {
  background: linear-gradient(to right, #fbf0c200, #FBF0C2);
  padding: 2px;
  border-radius: 30px;
  margin-bottom: 30px;
}
.ourProductSec .productBox .productBoxInner {
  display: flex;
  gap: 30px;
  align-items: center;
  background: linear-gradient(to right, #1b1b1b, #4b4c3e);
  border-radius: 30px;
}
.ourProductSec .productBoxInfo p {
  color: #FBF0C2;
  border-bottom: 1px solid #fbf0c224;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.ourProductSec .productBoxInfo {
  padding-right: 40px;
  width: 60%;
  padding-top: 20px;
}
.priceAndBuy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.priceAndBuy span.productPrice{
  font-size: 40px;
  color: #FFD452;
  font-family: Thunder-BoldLC;
  line-height: 40px;
}
.priceAndBuy button.productBuyBtn {
  font-family: Thunder-SemiBoldLC;
  background-color: #FFD452;
  font-size: 25px;
  text-transform: uppercase;
  padding: 14px;
  line-height: 30px;
  border: 0px;
  height: 50px;
  color: #000;
}
ul.membersTree {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  gap: 15px;
  margin-top: 10px;
}
ul.membersTree li img {
  border-radius: 50px;
  border: 3px solid #FBF0C2;
}
/* Modal Css */
.referralModal .modal-header {
  display: block;
  text-align: center;
  padding: 0px;
  border: 0px;
  position: relative;
}
.referralModal button.btn-close {
  background-image: url('../../images/close_icon.webp');
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 1;
  background-color: #2B2B2B;
  border-radius: 0px;
  padding: 20px;
}
.referralModal .modal-title.h4 {
  font-size: 90px;
  color: #FFD452;
  text-transform: uppercase;
  font-family: Thunder-BoldLC;
  line-height: 80px;
  max-width: 500px;
  margin: 60px auto 15px;
}
.referralModal .modal-dialog {
  max-width: 785px;
}
.referralModal .modal-content {
  background-color: #212121;
  border-radius: 50px;
  padding: 65px;
}
.referralModal p {
  font-size: 16px;
  color: #FBF0C2;
  line-height: 22px;
  margin: 0px;
}
.referralModal .modal-content .modal-footer {
  border: 0px;
  padding: 0px;
  display: block;
}
.referralModal .modal-content .modal-footer button.btn {
  background-color: #ffd452;
  border: 0;
  border-radius: 0;
  margin: 0px;
  color: #1b1b1b;
  display: block;
  font-family: Thunder-SemiBoldLC;
  font-size: 44px;
  padding-top: 15px;
  text-transform: uppercase;
  width: 100%;
}
.referralModal .modal-body {
  padding: 0px;
  margin: 20px 0px;
}
.referralModal .modal-body input.form-control {
  background-color: transparent;
  color: #FBF0C2;
  border: 2px solid #333333;
  padding: 28px 30px;
  border-radius: 15px;
  box-shadow: none;
}
.referralModal .modal-body input.form-control::placeholder {
  color: #fbf0c27a;
  font-weight: 100;
}
.modal-dialog-centered.modal.show {
  display: flex !important;
}
.radio-buttons {
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  margin-bottom: 15px;
  width: 100%;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-right: 5px;
}

.form-check-label {
  margin-right: 20px;
  color: #FBF0C2;
}
.font-z-thank .modal-content {
  border-radius: 10px;
}
.font-z-thank .modal-content .modal-title.h4 {
  margin: 0px auto 15px !important;
}
.font-z-thank .modal-content button.btn-close {
  padding: 10px !important;
  right: 20px !important;
  top: 20px !important;
}
.font-z-thank .modal-content .modal-header {
  position: unset !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  
  section.ourProductSec {
      margin-top: -48px;
        padding-bottom: 38px;
  }
  .ourProductSec .secTitle {
      padding: 70px 0px;
  }
  .productBoxImg {
    width: 100%;
  }
  .ourProductSec .productBoxInfo {
      width: 100%;
  }
  .productBoxImg img {
    height: 350px;
    object-fit: cover;
  }
  /* Modal Css */
  .referralModal .modal-content {
    border-radius: 20px;
    padding: 20px;
  }
  .referralModal .modal-title.h4 {
    font-size: 55px;
    line-height: 50px;
    max-width: 300px;
    margin: 40px auto 15px;
  }
  .font-z-thank .modal-content .modal-title.h4 {
      margin: 40px auto 15px!important;
  }
  .referralModal p {
    font-size: 14px;
  }
  .referralModal .modal-body {
    margin: 30px 0px;
  }
  .referralModal .modal-body input.form-control {
    padding: 20px 20px;
    border-radius: 10px;
  }
  .referralModal .modal-content .modal-footer button.btn {
    font-size: 30px;
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .referralModal button.btn-close {
    top: 5px;
    padding: 10px;
  }

  .modal-dialog-centered.modal.show {
    justify-content: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .ourProductSec .col-md-6 {
      width: 100%;
  }
  .productBoxInfo h3 {
      font-size: 50px;
      line-height: 49px;
  }
  .ourProductSec .productBoxInfo p {
      padding-bottom: 20px;
      margin-bottom: 20px;
      font-size: 14px;
  }
  .priceAndBuy span.productPrice {
      font-size: 35px;
  }
  .priceAndBuy button.productBuyBtn {
      font-size: 23px;
      padding: 15px;
      height: 50px;
      line-height: 25px;
  }
  .ourProductSec .secTitle {
      padding: 70px 0px;
  }
  section.ourProductSec {
      margin-top: -60px;
  }
  .scrollDown img {
      max-width: 130px;
  }
  /* Modal Css */
  .referralModal .modal-dialog {
    max-width: 700px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ourProductSec .productBox .productBoxInner {
    gap: 15px;
  }
  .productBoxImg {
    width: 50%;
  }
  .productBoxInfo h3 {
    font-size: 40px;
    line-height: 35px;
  }
  .ourProductSec .productBoxInfo p {
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-size: 13px;
  }
  .priceAndBuy button.productBuyBtn {
    font-size: 22px;
    padding: 10px;
    line-height: 25px;
    height: 40px;
  }
  .priceAndBuy span.productPrice {
    font-size: 30px;
    line-height: 40px;
  }
  ul.membersTree {
    gap: 10px;
  }
  .ourProductSec .productBoxInfo {
    padding-right: 20px;
  }
  ul.membersTree li img {
    width: 40px !important;
    height: 40px !important;
  }
  .scrollDown img {
      max-width: 130px;
  }
  section.ourProductSec {
      margin-top: -55px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ourProductSec .productBoxInfo {
      padding-right: 20px;
  }
  .productBoxInfo h3 {
      font-size: 50px;
      line-height: 42px;
  }
  .ourProductSec .productBoxInfo p {
      padding-bottom: 15px;
      margin-bottom: 15px;
      font-size: 13px;
  }
  .priceAndBuy span.productPrice {
      font-size: 35px;
  }
  .priceAndBuy button.productBuyBtn {
      font-size: 22px;
      padding: 10px;
      height: 43px;
  }
  ul.membersTree {
      gap: 8px;
  }
  ul.membersTree li img {
    width: 35px !important;
    height: 35px !important;
  }


  .referralModal .modal-title.h4 {
    font-size: 68px;
    line-height: 60px;
  }
  .referralModal .modal-content {
      border-radius: 35px;
      padding: 30px;
  }
  .referralModal .modal-dialog {
      max-width: 500px;
  }
  .referralModal button.btn-close {
      padding: 12px;
  }
  .referralModal .modal-content .modal-footer button.btn {
      padding: 16px 20px 8px;
      font-size: 32px;
      line-height: normal;
  }
  .referralModal .modal-body input.form-control {
      height: 47px;
  }
}

@media (min-width: 1400px) and (max-width: 1699px) {
  .ourProductSec .productBoxInfo {
      padding-right: 20px;
  }
  .productBoxInfo h3 {
      font-size: 50px;
      line-height: 49px;
  }
  .priceAndBuy span.productPrice {
      font-size: 35px;
  }
  .priceAndBuy button.productBuyBtn {
      font-size: 25px;
      padding: 15px;
      height: 50px;
  }
}