


.HowItHead {
    text-align: center;
}
.HowItWorks h1 {
    font-size: 120px;
    color: #FFD452;
    line-height: 140px;
    margin-bottom: 0px;
  
}
.how-des h3 {
    color: #FFD452;
    font-size: 16px;
    font-family: 'Roboto-Regular';
}
.HowItmiddle p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #FBF0C2;
    font-family: 'Roboto-Regular';
    line-height: 22px;
}
.how-img{
    text-align: right;
}
.how-img img{
    mix-blend-mode: lighten;
    height: 100%;
    width: 100%;
    max-width: 716px;

}
.HowItWorks {
    padding-top: 0px;
    background-image: url('../../images/how-it-works-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.HowItmiddle {
   
}
.HowItmiddle .row {
    align-items: center;
}
.how-des {
    
}







@media (min-width: 1400px) and (max-width: 1699px) {


    .HowItWorks h1 {
        font-size: 120px;
    }
    .HowItHead {
        margin-bottom: 58px;
    }
    .HowItmiddle p {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .HowItmiddle {
        margin-top: -120px;
    }
    .how-des {
        padding-top: 80px;
    }
    .how-img img {
        width: 100%;
    }
}


@media (min-width: 1200px) and (max-width: 1399px) {

    .HowItWorks h1 {
        font-size: 100px;
        line-height: 60px;
        margin-bottom: 20px;
    }
    .HowItHead {
    }
    .HowItmiddle p {
        margin-bottom: 16px;
        line-height: 20px;
        font-size: 14px;
    }
    .HowItWorks {
        padding-top: 42px;

    }
    .how-img img {
        width: 100%;
    }
}



@media (min-width: 992px) and (max-width: 1199px) {

    .HowItWorks h1 {
        font-size: 90px;
        line-height: 60px;
        margin-bottom: 20px;
    }
    .HowItHead {
    }
    .HowItmiddle p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
    }
    .HowItWorks {
        padding-top: 96px;
    }
    .how-img img {
        width: 100%;
    }
}



@media (min-width: 768px) and (max-width: 991px) {

    .how-img{
        text-align: left;
    }
    .HowItWorks h1 {
        font-size: 80px;
        line-height: 60px;
        margin-bottom: 20px;
        text-align: center;
    }
    .HowItHead {
    }
    .HowItmiddle p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 14px;
        text-align: center;
    }
    .HowItWorks {
        padding-top: 79px;
    }
    .how-img img {
        width: 100%;
        max-width: 499px;
    }
    .HowItmiddle .row {
        flex-direction: column-reverse;
    }
    .HowItmiddle .col-md-6 {
        width: 100%;
        text-align: center;
        
    }
  
    
}



@media (min-width: 320px) and (max-width: 767px) {

    .HowItWorks h1 {
        font-size: 80px;
        line-height: 67px;
        text-align: center;
    }
    .HowItmiddle p {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
    }
    .HowItHead {
        margin-bottom: 30px;
    }
    .HowItWorks {
        padding-top: 71px;
    }
    .HowItmiddle .row {
        flex-direction: column-reverse;
    }
    .how-img img {
        width: 100%;
    }
}



