.TermsConditionsSec {
    background-image: url('../../images/login-bg.webp');
    background-color: #1B1B1B;
    padding: 220px 0px 120px;
    margin-top: -100px;
    background-size: 100% 100%;
}
.TermsConditionsSec p {
    color: #FBF0C2;
    font-size: 18px;
    font-family: Roboto-Light;
    margin-bottom: 20px;
}
.TermsConditionsSec h3 {
    color: #FFD452;
    font-size: 72px;
    margin-bottom: 15px;
    margin-top: 65px;
    line-height: 70px;
}
.innerContainer {
    max-width: 1330px;
    margin: 0px auto;
}
.TermsConditionsSec ul li {
    color: #FBF0C2;
    font-size: 18px;
    font-family: Roboto-Light;
}
@media (min-width: 320px) and (max-width: 767px) {
    .TermsConditionsSec {
        padding: 160px 0px 70px;
        background-size: cover;
    }
    .TermsConditionsSec p {
        font-size: 15px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .TermsConditionsSec {
        padding: 160px 0px 70px;
        background-size: cover;
    }
}