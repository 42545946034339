.roadMapSec {
    background-image: url('../../images/road-map-bg.png');
    background-color: #1B1B1B;
    padding: 120px 0px;
    background-size: 100% 100%;
    position: relative;
}
.roadMapSec:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url('../../images/Flag.gif');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top right;
    mix-blend-mode: lighten;
    max-width: 500px;
    max-height: 500px;
}
.roadMapSec .secTitle p {
    max-width: 990px;
    margin: 0px auto;
}
.roadmapCenterCol {
    text-align: center;
}
.roadmapMain {
    display: flex;
    position: relative;
    padding-top: 120px;
}
.roadmapText h3 {
    color: #FFD452;
    font-size: 71px;
    z-index: 1;
    position: relative;
}
.roadmapText h3 span {
    font-size: 40px;
}
.roadmapRightCol .roadmapText:nth-child(3) h3 {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.roadmapRightCol .roadmapText:nth-child(3) h3 span {
    position: relative;
    top: 5px;
}
.roadmapText p {
    color: #FBF0C2;
}
.roadmapCenterCol {
    flex: 0 0 100%;
    max-width: 100%;
}
.roadmapLeftCol {
    flex: 0 0 31%;
    max-width: 31%;
    position: absolute;
    left: 0px;
}
.roadmapRightCol {
    flex: 0 0 23%;
    max-width: 23%;
    position: absolute;
    right: 0px;
}
.roadmapCenterCol img {
    margin-left: -113px;
    margin-top: -42px;
}
.roadmapLeftCol .roadmapText:nth-child(1) {
    margin-bottom: 34%;
}
.roadmapLeftCol .roadmapText:nth-child(2) {
    margin-bottom: 59%;
}
.roadmapLeftCol .roadmapText:nth-child(3) {
    margin-bottom: 30.5%;
}
.roadmapLeftCol .roadmapText:nth-child(4) {
    margin-bottom: 0%;
}
.roadmapRightCol .roadmapText:nth-child(1) {
    margin-top: 30%;
}
.roadmapRightCol .roadmapText:nth-child(2) {
    margin-top: 80%;
}
.roadmapRightCol .roadmapText:nth-child(3) {
    margin-top: 82% ;
}
.roadmapRightCol .roadmapText:nth-child(4) {
    margin-top: 77%;
}




.roadmapLeftCol .roadmapText:nth-child(2) {
    position: relative;
}
.roadmapLeftCol .roadmapText:nth-child(2):before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0px;
    right: -60px;
    max-width: 70px;
    max-height: 70px;
    border-radius: 100%;
    background-image: url('../../images/Roadmap-anim.gif');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
}

@media (min-width: 320px) and (max-width: 767px) {
    .roadmapCenterCol {
        display: none;
    }
    .roadmapLeftCol,
    .roadmapRightCol {
        /*flex: 0 0 100%;
        max-width: 100%;
        position: initial;*/

        display: none;
    }
    .roadmapMain {
        display: block;
        padding-top: 50px;
    }
    .roadMapSec {
        padding: 76px 0px 35px;
        background-size: cover;
    }
    .roadmapText h3 {
        font-size: 45px;
    }
    .roadmapText p {
        font-size: 13px;
    }
    .roadmapLeftCol .roadmapText,
    .roadmapRightCol .roadmapText {
        text-align: center;
        margin-bottom: 30px !important;
        margin-top: 0px !important;
    }
    .roadmapText h3 span {
        font-size: 32px;
        position: relative;
        top: 3px;

    }
    .forResMap h3 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;
    }
    .roadmapRightCol .roadmapText:nth-child(3) h3 {
        justify-content: flex-start;
        gap: 10px;
    }
    .roadmapRightCol .roadmapText:nth-child(3) h3 span {
        top: 2px;
    }

    .roadMapSec:before {
        max-width: 150px;
        max-height: 150px;
        background-size: cover;
        top: 0px;
    }
    .roadMapSec .secTitle p {
        max-width: 590px;
    }
    .roadmapLeftCol .roadmapText:nth-child(2):before {
        display: none;
    }   
}

@media (min-width: 768px) and (max-width: 991px) {
    .roadmapCenterCol {
        display: none;
    }
    .roadmapLeftCol,
    .roadmapRightCol {
        flex: 0 0 50%;
        max-width: 50%;
        position: initial;
    }
    .roadmapMain {
        padding-top: 80px;
        gap: 15px;
    }
    .roadMapSec {
        padding: 90px 0px 80px;
        background-size: cover;
    }
    .roadmapText h3 {
        font-size: 40px;
    }
    .roadmapText p {
        font-size: 13px;
        min-height: 59px;
        margin-bottom: 0px;
    }
    .roadmapLeftCol .roadmapText,
    .roadmapRightCol .roadmapText {
        margin-bottom: 30px !important;
        margin-top: 0px !important;
    }
    .roadmapRightCol .roadmapText:nth-child(3) h3 span {
        top: 0px;
    }


    .roadMapSec:before {
        max-width: 250px;
        max-height: 250px;
        background-size: cover;
        top: 50px;
    }
    .roadMapSec .secTitle p {
        max-width: 590px;
    }

    .roadmapLeftCol .roadmapText:nth-child(2):before {
        display: none;
    }

    
}
@media (min-width: 992px) and (max-width: 1199px) {
    .roadmapCenterCol img {
        margin-left: 33px;
        margin-top: -23px;
        max-width: 585px;
    }
    .roadmapCenterCol {
        flex: 0 0 70%;
        max-width: 70%;
        margin: 0px auto;
        position: relative;
        left: -70px;
    }
    .roadmapLeftCol {
        flex: 0 0 30%;
        max-width: 30%;
        position: absolute;
        left: 0px;
    }
    .roadmapRightCol {
        flex: 0 0 30%;
        max-width: 30%;
        right: -20px;
    }
    .roadmapText h3 {
        font-size: 40px;
    }
    .roadmapText h3 span {
        font-size: 33px;
    }
    .roadmapText p {
        font-size: 14px;
        max-width: 300px;
    }
    .roadmapLeftCol .roadmapText:nth-child(1) {
        margin-bottom: 22%;
    }
    .roadmapLeftCol .roadmapText:nth-child(2) {
        margin-bottom: 53%;
    }
    .roadmapLeftCol .roadmapText:nth-child(3) {
        margin-bottom: 16%;
    }
    .roadmapRightCol .roadmapText:nth-child(1) {
        margin-top: 23%;
    }
    .roadmapRightCol .roadmapText:nth-child(2) {
        margin-top: 48%;
    }
    .roadmapRightCol .roadmapText:nth-child(3) {
        margin-top: 58%;
    }
    .roadmapRightCol .roadmapText:nth-child(4) {
        margin-top: 35%;
    }
    .roadMapSec {
        padding: 100px 0px 130px;
        background-size: cover;
    }
    .roadmapRightCol .roadmapText:nth-child(3) h3 span {
        top: 0px;
    }

    .roadMapSec:before {
        max-width: 300px;
        max-height: 300px;
        background-size: cover;
        top: 50px;
    }
    .roadMapSec .secTitle p {
        max-width: 690px;
    }


    .roadmapLeftCol .roadmapText:nth-child(2):before {
        z-index: 1;
        top: -10px;
        right: -35px;
        max-width: 50px;
        max-height: 50px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .roadmapCenterCol img {
        margin-left: 69px;
        margin-top: -28px;
        max-width: 637px;
    }
    .roadmapCenterCol {
        flex: 0 0 75%;
        max-width: 75%;
        margin: 0px auto;
        position: relative;
        left: -114px;
    }
    .roadmapLeftCol {
        flex: 0 0 30%;
        max-width: 30%;
        position: absolute;
        left: 0px;
    }
    .roadmapRightCol {
        flex: 0 0 30%;
        max-width: 30%;
    }
    .roadmapText h3 {
        font-size: 48px;
    }
    .roadmapText p {
        font-size: 14px;
        max-width: 250px;
    }
    .roadmapLeftCol .roadmapText:nth-child(1) {
        margin-bottom: 14%;
    }
    .roadmapLeftCol .roadmapText:nth-child(2) {
        margin-bottom: 49%;
    }
    .roadmapLeftCol .roadmapText:nth-child(3) {
        margin-bottom: 10%;
    }
    .roadmapRightCol .roadmapText:nth-child(1) {
        margin-top: 20%;
    }
    .roadmapRightCol .roadmapText:nth-child(2) {
        margin-top: 46%;
    }
    .roadmapRightCol .roadmapText:nth-child(3) {
        margin-top: 54%;
    }
    .roadmapRightCol .roadmapText:nth-child(4) {
        margin-top: 50%;
    }
    .roadMapSec {
        padding: 100px 0px 130px;
        background-size: cover;
    }
    .roadmapRightCol .roadmapText:nth-child(3) h3 span {
        top: 2px;
    }

    .roadMapSec .secTitle p {
        max-width: 690px;
    }

    .roadMapSec:before {
        max-width: 385px;
        max-height: 385px;
        background-size: cover;
    }

    .roadmapLeftCol .roadmapText:nth-child(2):before {
        z-index: 1;
        top: -7px;
        right: -49px;
        max-width: 55px;
        max-height: 55px;
    }
    
}

@media (min-width: 1400px) and (max-width: 1699px) {
    .roadmapText h3 {
        font-size: 55px;
    }
    .roadmapText h3 span {
        font-size: 33px;
    }
    .roadmapCenterCol {
        flex: 0 0 74%;
        max-width: 74%;
    }
    .roadmapCenterCol img {
        margin-left: 191px;
        margin-top: -36px;
        max-width: 810px;
    }
    .roadmapLeftCol {
        left: 20px;
    }
    .roadmapLeftCol .roadmapText:nth-child(1) {
        margin-bottom: 31%;
    }
    .roadmapLeftCol .roadmapText:nth-child(2) {
        margin-bottom: 56%;
    }
    .roadmapLeftCol .roadmapText:nth-child(3) {
        margin-bottom: 27.5%;
    }
    .roadmapLeftCol .roadmapText:nth-child(4) {
        margin-bottom: 40%;
    }
    .roadmapRightCol .roadmapText:nth-child(1) {
        margin-top: 30%;
    }
    .roadmapRightCol .roadmapText:nth-child(2) {
        margin-top: 70%;
    }
    .roadmapRightCol .roadmapText:nth-child(3) {
        margin-top: 77%;
    }
    .roadmapRightCol .roadmapText:nth-child(4) {
        margin-top: 65%;
    }
    .roadMapSec .secTitle p {
        max-width: 780px;
    }

    .roadmapLeftCol .roadmapText:nth-child(2):before {
        top: -3px;
        right: -28px;
        max-width: 55px;
        max-height: 55px;
    }

}



/* Responsive Map CSS */


.forResMap {
    display: none;
}



@media (min-width: 320px) and (max-width: 767px) {

    .forResMap {
        display: block;
    }
    .forResMap .roadmapText {
        text-align: center;
        margin-bottom: 30px !important;
        margin-top: 0px !important;
    }

}
