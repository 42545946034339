.current_balance h3,
.wallet_address h3,
.wallet_transaction h3,
.withdraw_main h3 {
    color: #FFD452;
    font-size: 28px;
    font-family: Roboto-Regular;
    margin-bottom: 32px;
    text-transform: capitalize;
}
.current_balance h3 {
    margin-bottom: 20px;
}
.withdraw_main h3 {
    margin-bottom: 60px;
}
.currency_box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 272px;
    background-color: #242424;
    padding: 16px 24px 24px;
    border-radius: 16px;
}
.currency-box-main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 64px;
}
.currency_box h2 {
    color: #FBF0C2;
    width: 50%;
    font-size: 24px;
    font-family: 'Roboto-Medium';
    margin-bottom: 65px;
}
.currency_box h3 {
    width: 100%;
    color: #FBF0C2;
    font-size: 40px !important;
    font-family: 'Roboto-Bold';
    margin-bottom: 0px;
}
.current_balance {
    margin-bottom: 70px;
}
.address_field {
    display: flex;
    border: 1px solid #333333;
    max-width: 800px;
    justify-content: space-between;
    padding: 32px;
    align-items: center;
    position: relative;
    border-radius: 16px;
    margin-bottom: 95px;
}
.address_field p {
    color: #FBF0C2;
    margin: 0px;
    font-size: 18px;
}
.copyButton {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border: 1px solid;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    color: #1B1B1B;
    font-size: 32px;
    font-family: 'Thunder-SemiBoldLC';
    text-transform: uppercase;
    padding: 0px 32px;
    background-color: #FBF0C2;
    line-height: initial;
}

.transaction_table {
    margin: 0px auto;
    border-radius: 30px;
    background-color: #1b1b1b59;
    box-shadow: 0px 3px 20px #0000007a;
}
.transaction_table .table>thead {
    background-color: #FFD452;
}
.transaction_table .table>:not(caption)>*>* {
    background-color: transparent;
    padding: 16px 32px;
    border-color: #5757574d !important;
    white-space: nowrap;
}
.transaction_table .table>thead tr th:first-child,
.transaction_table .table>thead tr th:last-child,
.transaction_table .table>tbody tr td:first-child,
.transaction_table .table>tbody tr td:last-child {
    border: 0px;
}
.transaction_table .table>thead tr {
    border-top: 0px;
}
.transaction_table .table>tbody tr:last-child {
    border-bottom: 0px;
}
.transaction_table .table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0;
    border-color: #5757574d !important;
}
.transaction_table .table>thead tr th {
    padding: 24px 32px;
    font-size: 20px;
}
.transaction_table .table>tbody tr td {
    font-size: 18px;
}
.transaction_table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #000;
}
.transaction_table::-webkit-scrollbar-thumb {
    background-color: #ffdc64;
}
.align_center {
    text-align: center;
}
.transaction_view_btn {
    font-family: Thunder-SemiBoldLC;
    background-color: #FFD452;
    font-size: 22px;
    text-transform: uppercase;
    padding: 0px 24px;
    line-height: 38px;
    border: 0px;
    height: 35px;
    color: #000;
    text-decoration: none;
    display: inline-block;
}
button.withdrawBtn {
    background-color: #FBF0C2;
    border: 0;
    border-radius: 0;
    color: #1b1b1b;
    display: block;
    font-family: Thunder-SemiBoldLC;
    font-size: 32px;
    text-transform: uppercase;
    padding: 27.5px 48px;
    line-height: 25px;
    float: right;
    border-radius: 0px 16px 16px 0px;
    position: absolute;
    top: 0px;
    right: 15px;
    z-index: 3;
}
.withdrawal_status {
    display: flex;
    max-width: 450px;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
}
.withdrawal_status p {
    color: #FBF0C2;   
    font-size: 18px;
    margin: 0px;
}
.withdrawal_status span {
    color: #FFD452;
    font-size: 18px;
}
h2.withdrawamount_title {
    font-size: 28px;
    color: #FFD452;
    font-family: 'Roboto-Medium';
    margin-bottom: 32px;
    text-transform: capitalize;
}

.withdraw_form {
    width: 100%;
    max-width: 820px;
}
button.fatch_amount_btn {
    background-color: #FBF0C2;
    border: 0;
    border-radius: 0;
    color: #1b1b1b;
    display: block;
    font-family: Thunder-SemiBoldLC;
    font-size: 24px;
    text-transform: uppercase;
    padding: 24px 40px;
    line-height: 17px;
   
}
button.create_wallet_btn {
    background-color: #FBF0C2;
    border: 0;
    border-radius: 0;
    color: #1b1b1b;
    display: block;
    font-family: Thunder-SemiBoldLC;
    font-size: 32px;
    text-transform: uppercase;
    padding: 45px 50px;
    line-height: 22px;
    margin-bottom: 100px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .address_field {
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 50px;
    }
    .address_field p {
        font-size: 15px;
        word-break: break-all;
        padding-right: 60px;
    }
    .copyButton {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 0px;
        padding: 0px;
    }
    .current_balance h3, .wallet_address h3, .wallet_transaction h3, .withdraw_main h3 {
        font-size: 23px;
    }
    .current_balance {
        margin-bottom: 50px;
    }
    .current_balance h2 {
        font-size: 30px;
    }
    button.create_wallet_btn {
        font-size: 25px;
        padding: 20px 35px;
        margin-bottom: 50px;
    }
    .fatch_balance {
        text-align: left;
        margin-top: 0px;
        margin-bottom: 50px;
    }
    #uncontrolled-tab-example-tabpane-withdraw > .row {
        flex-direction: column-reverse;
    }
    button.fatch_amount_btn {
        font-size: 22px;
        padding: 20px 30px;
        margin: initial;
    }
    .withdraw_main h3 {
        margin-bottom: 30px;
    }
    button.withdrawBtn {
        font-size: 20px;
        padding: 16.5px 20px;
        top: auto;
        bottom: 0px;
        right: 0px;
    }
    .currency_box {
        max-width: 100%;
    }

    .withdrawal_status {
        margin-top: 50px;
    }
    .withdrawal_status p,
    .withdrawal_status span  {
        font-size: 15px;
    }


    .fromTwo {
        margin-bottom: 0px !important;
    }
    .fromTwo label {
        position: unset !important;
        height: auto !important;
    }
    .withdraw_form .fromTwo input {
        margin: 0px 0px 30px !important;
        width: 100% !important;
    }
     .withdraw_form  .fromAmount input {
        margin: 0px 0px 0px !important;
    }
    .fromAmount input {
        position: unset !important;
        height: 60px !important;
    }
    .fromAmount {
        height: auto !important;
    }

    .fromMain input {
        padding: 0px 55px 0px;
    }
}


@media (min-width: 768px) and (max-width: 991px) {
    .current_balance h3, .wallet_address h3, .wallet_transaction h3, .withdraw_main h3 {
        font-size: 18px;
    }
    .current_balance h2 {
        font-size: 30px;
    }
    button.create_wallet_btn {
        font-size: 20px;
        padding: 17px 30px;
        margin-bottom: 50px;
    }
    .current_balance {
        margin-bottom: 40px;
    }
    .address_field p {
        font-size: 15px;
    }
    .copyButton {
        font-size: 0px;
        padding: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .address_field {
        padding: 22px;
        border-radius: 10px;
        margin-bottom: 50px;
    }
    .fatch_balance {
        text-align: left;
        margin-top: 0px;
        margin-bottom: 50px;
    }
    #uncontrolled-tab-example-tabpane-withdraw > .row {
        flex-direction: column-reverse;
    }
    #uncontrolled-tab-example-tabpane-withdraw > .row .col-md-9,
    #uncontrolled-tab-example-tabpane-withdraw > .row .col-md-3 {
        width: 100%;
    }
    button.fatch_amount_btn {
        margin: initial;
    }


    .fromTwo {
        margin-bottom: 0px !important;
    }

    .currency_box {
        max-width: 227px;
    }
    button.withdrawBtn {
        padding: 27.5px 32px;
        right: -25px;
        font-size: 22px;
    }

    h2.withdrawamount_title {
        font-size: 18px;
    }
}


@media (min-width: 992px) and (max-width: 1199px) {

    .withdraw_form .fromTwo input {
        width: 80% !important;
    }
    button.withdrawBtn {
        right: 47px;
        padding: 27.5px 35px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    button.fatch_amount_btn {
        font-size: 20px;
    }
    .current_balance h3, .wallet_address h3, .wallet_transaction h3, .withdraw_main h3 {
        font-size: 22px;
    }
    .current_balance h2 {
        font-size: 35px;
    }
    button.create_wallet_btn {
        font-size: 30px;
        padding: 35px 40px;
        margin-bottom: 70px;
    }
    .address_field {
        padding: 25px;
        margin-bottom: 70px;
    }
    button.withdrawBtn {
        right: 7px;
    }
}




.personalForm {
}

.fromMain {
    position: relative;
    width: 100%;
}
.fromMain label {
    position: absolute;
    top: 0px;
    left: 0px;
}
.fromMain input {
    height: auto;
    border: 0px !important;
    padding: 0px 88px 0px;
}
.fromMain input:focus-visible {
    outline: 0px;
}
.fromTwo {
    position: relative;
/*    margin-bottom: 31px;*/
    width: 100%;
}
.fromTwo label {
    position: absolute;
    top: 29px;
    left: 0px;
    /*width: 100%;
    height: 100%;*/
}
.fromTwo input {
    width: 88% !important;
    margin-left: 85px;
}
.fromAmount {
    height: 80px;
}
.fromAmount input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}



