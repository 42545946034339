

.greenColor td{
    color: green !important;
}

.redColor td{
    color: red !important;
}

.formSec {
    background-image: url('../../images/login-bg.webp');
    background-color: #1B1B1B;
    padding: 220px 0px 120px;
    margin-top: -100px;
    background-size: cover;
}
.formBox {
    background: linear-gradient(to right, #fbf0c200, #fbf0c240);
    padding: 1px;
    border-radius: 60px;
    max-width: 785px;
    margin: 0px auto;
}
.loginForm {
    padding: 65px;
    background-color: #212121;
    border-radius: 60px;
}
.loginForm h3 {
    color: #FFD452;
    font-size: 96px;
    text-align: center;
    line-height: 90px;
}
.loginForm p {
    color: #FBF0C2;
    text-align: center;
    margin-bottom: 55px;
}
.loginForm .form-control {
    border: 1px solid #333333;
    height: 88px;
    color: #FBF0C2 !important;
    background-color: transparent !important;
    border-radius: 16px;
    padding: 0px 32px;
    font-family: Roboto-Light;
    box-shadow: none !important;
    margin-bottom: 30px;
}
.loginForm .form-control::placeholder {
    color: #FBF0C2;
}
.loginForm .forgetPass {
    color: #FFD452;
    font-size: 18px;
    text-decoration: none;
    display: block;
    position: relative;
    top: -10px;
}
.button_trnx_pad{
    padding: 6px!important;
    text-align: center;
}
.button_trnx td{
    color: #FBF0C2!important;
}
.button_trnx a{
    text-decoration: none;
    font-family: Thunder-SemiBoldLC;
    background-color: #FFD452;
    font-size: 20px;
    text-transform: uppercase;
    /* padding: 8px 20px; */
    line-height: 44px;
    border: 0px;
    height: 40px;
    color: #000;
    margin: 10px;
    display: inline-block;
    text-align: center;
    width: 50px;
}
.loginForm button.loginBtn {
    text-transform: uppercase;
    display: block;
    width: 100%;
    font-size: 44px;
    background-color: #FFD452;
    border: 0px;
    font-family: 'Thunder-SemiBoldLC';
    color: #1B1B1B;
    padding-top: 15px;
    border-radius: 0px;
}
.loginForm button.loginBtn:active {
    background-color: #FFD452;
    color: #1B1B1B;
}
.loginForm .formSwitch {
    color: #FFD452;
    font-size: 20px;
    display: block;
    text-align: center;
    margin-top: 20px;
    text-decoration: underline;
    font-family: Roboto-Medium;
    cursor: pointer;
}
.loginForm .form-control[type=number]::-webkit-outer-spin-button, 
.loginForm .form-control[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (min-width: 320px) and (max-width: 767px) {
    .formBox {
        border-radius: 20px;
    }
    .loginForm {
        padding: 50px 20px;
        border-radius: 20px;
    }
    .formSec {
        padding: 150px 0px 70px;
    }
    .loginForm h3 {
        font-size: 45px;
        line-height: 50px;
    }
    .loginForm p {
        font-size: 14px;
    }
    .loginForm .form-control {
        height: 60px;
        padding: 0px 20px;
        margin-bottom: 15px;
    }
    .loginForm button.loginBtn {
        font-size: 30px;
        padding-top: 10px;
    }
    .loginForm .formSwitch {
        font-size: 16px;
    }

    .loginForm .forgetPass {
        font-size: 14px;
        margin-left: 5px;
        top: 0px;
        margin-bottom: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .loginForm h3 {
        font-size: 86px;
        line-height: 80px;
    }
    .loginForm p {
        font-size: 14px;
    }
    .loginForm .form-control {
        height: 75px;
        padding: 0px 20px;
        margin-bottom: 20px;
    }
    .loginForm .forgetPass {
        top: 0px;
        margin-bottom: 10px;
    }
    .loginForm button.loginBtn {
        font-size: 35px;
    }
}