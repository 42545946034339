.notFoundPage {
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffd452;
    padding-top: 100px;
}
.notFoundPage h1 {
    font-size: 100px;
}