section.ourProductSec {
    background-image: url('../../images/our-product-bg.webp');
    background-color: #1B1B1B;
    margin-top: -100px;
    padding-bottom: 90px;
    background-size: cover;
}
.scrollDown {
    position: relative;
    text-align: center;
    z-index: 1;
}
.ourProductSec .secTitle {
    align-items: flex-end;
    padding: 100px 0px;
}
.ourProductSec .secTitle h1 {
    max-width: 400px;
    margin-bottom: 0px;
}


h1.ourProductSecTitle {
    z-index: 1;
    mix-blend-mode: lighten;
    max-width: 500px !important;
}
h1.ourProductSecTitle:before {
    content: '';
    width: 100%;
    height: 354px;
    display: block;
    position: absolute;
    left: -25px;
    z-index: -1;
    background-image: url(../../images/Bird-Flip.gif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    top: -145px;
}



@media (min-width: 320px) and (max-width: 767px) {

    h1.ourProductSecTitle {
        max-width: 296px !important;
    }
    h1.ourProductSecTitle:before {
        height: 249px;
        top: -100px;
        left: -5px;
    }

        
}
@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
    .ourProductSec .secTitle h1 {
        max-width: 330px;
    }

    h1.ourProductSecTitle {
        max-width: 398px !important;
    }
    h1.ourProductSecTitle:before {
        height: 235px;
        left: -40px;
        top: -118px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    
    h1.ourProductSecTitle {
        max-width: 443px !important;
    }
}

@media (min-width: 1400px) and (max-width: 1699px) {

}