


.OurTeamHead {
    text-align: center;
}
.OurTeamHead h1 {
    color: #FFD452;
    font-size: 120px;
    line-height: 140px;
    margin-bottom: 32px;
}
.OurTeamHead p {
    font-size: 16px;
    color: #FBF0C2;
    line-height: 22px;
    font-family: 'Roboto-Regular';
    width: 100%;
    max-width: 975px;
    margin: 0px auto 0px;
}
.OurTeamBoxes {
    margin-top: 80px;
}
.BoxContent {
    text-align: center;
}
.BoxContent h3 {
    margin-bottom: 16px;
    font-size: 26px;
    color: #FFD452;
    font-family: 'Roboto-Medium';
    font-weight: 600;
}
.BoxContent p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #FBF0C2;
    line-height: 22px;
    font-family: 'Roboto-Regular';
}

.BoxContent img {
    margin-bottom: 32px;
    width: 100%;
}
.OurTeam {
    padding-top:90px;
    padding-bottom: 90px;
    background-image: url('../../images/Our-Team-Bg.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.w-20{
    width: 20%!important;
}
@media (min-width: 1400px) and (max-width: 1699px) {

    .OurTeamHead h1 {
        font-size: 120px;
        line-height: 80px;
        margin-bottom: 24px;
    }
    .OurTeamHead p {
        font-size: 15px;
        max-width: 906px;
    }
    .OurTeamBoxes {
        margin-top: 80px;
    }
    .BoxContent h3 {
      
        margin-bottom: 13px;
    }
    .BoxContent p {
       
        line-height: 20px;
    }
}


@media (min-width: 1200px) and (max-width: 1399px) {


    .OurTeamHead h1 {
        font-size: 100px;
        line-height: 80px;
        margin-bottom: 20px;
    }
    .OurTeamHead p {
        font-size: 14px;
        line-height: 20px;
        max-width: 875px;
    }
    .OurTeamBoxes {
        margin-top: 60px;
    }
    .BoxContent h3 {
        font-size: 22px;
        margin-bottom: 12px;
    }
    .BoxContent img {
        margin-bottom: 22px;
    }
    .OurTeam {
        padding-top: 88px;
        padding-bottom: 85px;
        background-size: cover;
    }
    .BoxContent p {
        font-size: 14px;
    }
}



@media (min-width: 992px) and (max-width: 1199px) {

    .OurTeamHead h1 {
        font-size: 90px;
        line-height: 60px;
        margin-bottom: 22px;
    }
    .OurTeamHead p {
        color: #FBF0C2;
        font-size: 14px;
        max-width: 873px;
        line-height: 20px;
    }
    .OurTeamBoxes {
        margin-top: 50px;
    }
    .BoxContent h3 {
        margin-bottom: 10px;
        font-size: 19px;
    }
    .BoxContent p {
        font-size: 15px;
        line-height: 18px;
    }
    .OurTeam {
        padding-top: 94px;
        padding-bottom: 86px;
        background-size: cover;
    }
}



@media (min-width: 768px) and (max-width: 991px) {
    .how-des {
        position: relative;
        top: 0px;
    }
    .OurTeamHead h1 {
        font-size: 100px;
        line-height: 60px;
        margin-bottom: 22px;
    }
    .OurTeamHead p {
        font-size: 14px;
        line-height: 16px;
        max-width: 595px;
    }
    .OurTeamBoxes {
        margin-top: 50px;
    }
    .BoxContent h3 {
        font-size: 20px;
        margin-bottom: 12px;
    }
    .BoxContent img {
        margin-bottom: 22px;
    }
    .OurTeam {
        background-size: cover;
        padding-top: 100px;
        padding-bottom: 43px;
    }
    .w-20 {
        width: 33% !important;
        display: inline-block;
        margin-bottom: 40px;
    }
    .OurTeamBoxes{
        text-align: center;
    }
    .OurTeamBoxes .row{
        display: block;
    }
}


@media (min-width: 320px) and (max-width: 767px) {

    .OurTeamHead h1 {
        font-size: 80px;
        line-height: 50px;
        margin-bottom: 22px;
    }
    .w-20 {
        width: 80% !important;
        margin: 33px auto;
    }
    .how-img img {
        mix-blend-mode: lighten;
        width: 100%;
    }
    .how-des {
        position: relative;
        top: 0px;
    }
    .OurTeamHead p {
        font-size: 13px;
        line-height: 19px;
    }
    .OurTeamBoxes {
        margin-top: 40px;
    }
    .BoxContent img {
        margin-bottom: 22px;
    }
    .BoxContent h3 {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .BoxContent p {
        font-size: 18px;
        line-height: 18px;
    }
    .OurTeamBoxes .col-md-3 {
        margin-bottom: 30px;
    }
    .OurTeam {
        background-size: cover;
        padding-top: 81px;
        padding-bottom: 36px;
    }
}