.buySellSec {
    background-image: url('../../images/buy-sell-bg.webp');
    background-color: #1B1B1B;
    padding: 150px 0px 120px;
    background-size: cover;
    background-blend-mode: difference;
}
.buySellSec .secTitle {
    max-width: 1255px;
    margin: 0px auto;
}
.buySellSec .secTitle h1 {
    margin-bottom: 50px;
}
.buySellTable {
    max-width: 1270px;
    margin: 0px auto;
    border-radius: 30px;
    background-color: #1b1b1b59;
    box-shadow: 0px 3px 20px #0000007a;
    margin-top: 100px;
}
.buySellTable .table>thead {
    background-color: #FFD452;
}
.buySellTable .table>:not(caption)>*>* {
    background-color: transparent;
    padding: 16px 32px;
    border-color: #5757574d !important;
    white-space: nowrap;
}
.buySellTable .table>thead tr th:first-child,
.buySellTable .table>thead tr th:last-child,
.buySellTable .table>tbody tr td:first-child,
.buySellTable .table>tbody tr td:last-child {
    border: 0px;
}
.buySellTable .table>thead tr {
    border-top: 0px;
}
.buySellTable .table>tbody tr:last-child {
    border-bottom: 0px;
}
.buySellTable .table-bordered>:not(caption)>* {
    border-width: var(--bs-border-width) 0;
    border-color: #5757574d !important;
}
.buySellTable .table>thead tr th {
    padding: 24px 32px;
    font-size: 20px;
}
.buySellTable .table>tbody tr td {
    font-size: 18px;
}
.redColor, .redColor a {
    color: #E51E25 !important;
}
.greenColor, .greenColor a {
    color: #24B24C !important;
}
.buySellTable table.table {
    margin: 0px;
}
.buySellSec .secTitle h1:before {
    content: '';
    background-image: url('../../images/buy-sell-prop.png');
    position: absolute;
    top: -75px;
    left: 240px;
    width: 200px;
    height: 200px;
    background-size: cover;
}

@media (min-width: 320px) and (max-width: 767px) {
    .buySellSec .secTitle h1:before {
        content: none;
    }
    .buySellSec {
        padding: 100px 0px 80px;
    }
    .buySellSec .secTitle h1 {
        margin-bottom: 20px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .buySellSec .secTitle h1:before {
        content: none;
    }
    .buySellSec {
        padding: 90px 0px 90px;
    }
    .buySellTable .table>tbody tr td {
        font-size: 15px;
    }
    .buySellSec .secTitle h1 {
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .buySellSec .secTitle h1:before {
        top: -64px;
        left: 165px;
        width: 160px;
        height: 160px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .buySellSec .secTitle h1:before {
        top: -60px;
        left: 250px;
        width: 150px;
        height: 150px;
    }
    .buySellSec .secTitle h1 {
        margin-bottom: 50px;
    }
}