.workSecOne {
    background-image: url('../../images/login-bg.webp');
    background-color: #1B1B1B;
    padding: 120px 0px;
    background-size: cover;
}
.top_space220 {
    padding-top: 220px;
    margin-top: -100px;
}
.wrokText.secTitle h1 {
    font-size: 100px;
}
.wrokText p {
    max-width: 1070px;
    margin: 0px auto;
    padding-bottom: 100px;
}
.wrokText img {
    width: 100%;
    cursor: pointer;
}
.workSecTwo {
    background-image: url('../../images/work-bg.webp');
    padding: 120px 0px;
    background-size: cover;
}
.wrokText .video-react {
    padding-top: 700px !important;
    background-color: transparent;
}
.wrokText .video-react .video-react-poster {
    background-color: transparent;
}
.wrokText .video-react .video-react-big-play-button {
    display: none;
}

@media (min-width: 320px) and (max-width: 767px) {
    .wrokText.secTitle h1 {
        font-size: 64px;
        margin-bottom: 20px;
    }
    .workSecOne {
        padding: 170px 0px 58px;
        background-size: cover;
    }
    .workSecLast {
        padding: 70px 0px 60px;
    }
    .wrokText p {
        padding-bottom: 40px;
    }
    .workSecTwo {
        padding: 74px 0px 62px;
        background-size: cover;
    }
    .wrokText .video-react {
        padding-top: 150px !important;
        padding-bottom: 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .wrokText.secTitle h1 {
        font-size: 100px;
        margin-bottom: 20px;
        line-height: 80px;
    }
    .wrokText .video-react {
        padding-top: 300px !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .wrokText.secTitle h1 {
        font-size: 100px;
        margin-bottom: 20px;
        line-height: 80px;
    }
    .wrokText .video-react {
        padding-top: 400px !important;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .wrokText.secTitle h1 {
        margin-bottom: 20px;
        line-height: 80px;
    }
    .wrokText .video-react {
        padding-top: 500px !important;
    }
}
@media (min-width: 1400px) and (max-width: 1699px) {
    .wrokText .video-react {
        padding-top: 600px !important;
    }
}